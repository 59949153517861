import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';
import { ServerURLS, EnvironmentConstants } from 'src/environments/environment';
import { ProfileEdit } from '../profileEdit';
import { Address } from '../address';

import pdfMake from '../../../../../node_modules/pdfmake/build/pdfmake';
import pdfFonts from '../../../../../node_modules/pdfmake/build/vfs_fonts';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { ScCommonService } from 'src/app/services/common/sc-common.service';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { Router } from '@angular/router';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-profile-v2',
  templateUrl: './profile-v2.component.html',
  styleUrls: ['./profile-v2.component.scss']
})
export class ProfileV2Component implements OnInit {

  editProfile:FormGroup
  successMsg:string;
  errMsg:String;
  profileEditForm:ProfileEdit;
  loggedUserId:any;
  loggedEntityType:any;
  isEditMode:boolean;

  addressForm:FormGroup
  addrSuccessMsg: any;
  addrErrMsg: any;
  loggedUserAddrId:any;
  address: Address;
  isEditAddrMode: boolean;
  userData: any;
  image: any;
  selectedFile: any;

  closeResult = '';

  passwordForm:FormGroup
  pwdPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
  successMsg1:string;
  errMsg1:String;

  timezones: { name: string; value: any; }[];
  errorMessage: string;
  successMessage: any;
  pinCode: string;
  isPincodeReleased: boolean = false;

  constructor( private router: Router,private modalService: NgbModal, private _formBuilder: FormBuilder,private scHttpService: ScHttpService,  public scAuthService: ScAuthService, private storageService: ScStorageService, private scCommonService: ScCommonService ) {
    this.profileEditForm = new ProfileEdit();
    this.address = new Address();
}

ngOnInit() {
    
  this.userData = this.scAuthService.getLoggedUserData();
  this.loggedUserId = this.scAuthService.getLoggedUserId() ;
  this.loggedEntityType = this.scAuthService.getLoggedEntityType() ;
 
  this.loadFormData();

  if( this.loggedUserId ){
    this.initProfileData(this.loggedUserId);
  }
  this.timezones = [
    { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "-12:00"},
                { name: "(GMT -11:00) Midway Island, Samoa", value: "-11:00"},
                { name: "(GMT -10:00) Hawaii", value: "-10:00"},
                { name: "(GMT -9:30) Taiohae", value: "-09:50"},
                { name: "(GMT -9:00) Alaska", value: "-09:00"},
                { name: "(GMT -8:00) Pacific Time (US &amp; Canada)", value: "-08:00"},
                { name: "(GMT -7:00) Mountain Time (US &amp; Canada)", value: "-07:00"},
                { name: "(GMT -6:00) Central Time (US &amp; Canada), Mexico City", value: "-06:00"},
                { name: "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima", value: "-05:00"},
                { name: "(GMT -4:30) Caracas", value: "-04:50"},
                { name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "-04:00"},
                { name: "(GMT -3:30) Newfoundland", value: "-03:50"},
                { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "-03:00"},
                { name: "(GMT -2:00) Mid-Atlantic", value: "-02:00"},
                { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "-01:00"},
                { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "+00:00"},
                { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "+01:00"},
                { name: "(GMT +2:00) Kaliningrad, South Africa", value: "+02:00"},
                { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "+03:00"},
                { name: "(GMT +3:30) Tehran", value: "+03:50"},
                { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "+04:00"},
                { name: "(GMT +4:30) Kabul", value: "+04:50"},
                { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "+05:00"},
                { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "+05:50"},
                { name: "(GMT +5:45) Kathmandu, Pokhara", value: "+05:75"},
                { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "+06:00"},
                { name: "(GMT +6:30) Yangon, Mandalay", value: "+06:50"},
                { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "+07:00"},
                { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "+08:00"},
                { name: "(GMT +8:45) Eucla", value: "+08:75"},
                { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "+09:00"},
                { name: "(GMT +9:30) Adelaide, Darwin", value: "+09:50"},
                { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "+10:00"},
                { name: "(GMT +10:30) Lord Howe Island", value: "+10:50"},
                { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "+11:00"},
                { name: "(GMT +11:30) Norfolk Island", value: "+11:50"},
                { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "+12:00"},
                { name: "(GMT +12:45) Chatham Islands", value: "+12:75"},
                { name: "(GMT +13:00) Apia, Nukualofa", value: "+13:00"},
                { name: "(GMT +14:00) Line Islands, Tokelau", value: "+14:00"}
  ];
}

setTimeZone(value){
  this.updateTimezone( value );
  this.successMessage = '';
}

updateTimezone( timezone ) {
    
  let params = "?entity-id=" + this.loggedUserId + "&time-zone=" + timezone;
  let promise = this.scHttpService.putHttpService(ServerURLS.updateTimezone.action + params, '');
  
  promise.then( (result) => {
    this.errorMessage = '';
    this.successMessage = result['message'];
    this.initProfileData(this.loggedUserId);
  });

  promise.catch ( (error) => {
    this.errorMessage = error['message'];
    this.successMessage = '';
  });

}

loadFormData(){

  this.editProfile = this._formBuilder.group({
    firstName: [],
    lastName: [],
    email: [{value: '', disabled: true}],
    phoneNumber: [{value: '', disabled: true}],
    loggedUserId :[this.scAuthService.getLoggedUserId()],
    timeZone:[],
  });

  this.addressForm = this._formBuilder.group({
    addrLine1: ['',Validators.required],
    addrLine2: [],
     city: [ { value:'', disabled: true} , Validators.required ],
     state: [{ value:'', disabled: true} , Validators.required],
    country: [ { value:'', disabled: true} ,Validators.required],
    zipCode: [ '',[Validators.required, Validators.maxLength(6)]],

});

  this.passwordForm = this._formBuilder.group({
    currentPassword: ['', this.userData.isPwdCrtd ? [Validators.required, Validators.pattern(this.pwdPattern)] : ''],
    password: ['', [Validators.required, Validators.pattern(this.pwdPattern)]],
    confirmPassword: ['', Validators.required],
    loggedUserId :[this.scAuthService.getLoggedUserId()],

  }, {
    validator: MustMatch('password', 'confirmPassword')
  });

}

get l() { return this.addressForm.controls; }

loadZipCodeData(event) {

  this.isPincodeReleased = true;
  this.pinCode = event.srcElement.value;//formData["zipCode"];

  if (this.pinCode == "" || this.pinCode.length < 5) {
    this.addressForm.patchValue({
      'zipCode': '',
      'city': '',
      'state': '',
      'country': '',
    });
    return;
  }

  let url = ServerURLS.geoLocationDetails.action + "/" + this.pinCode;

  let promise_ = this.scHttpService.getHttpService(url, '');
  promise_.then(value => {

    this.addressForm.patchValue({
      'zipCode': this.pinCode,
      'city': value["data"]["city"],
      'state': value["data"]["state"],
      'country': value["data"]["country"],
    });

  });

  promise_.catch(error => { // on error and other than http code:200
    //this.errMsg =  error['message'];
    this.addressForm.patchValue({
      'zipCode': '',
      'city': '',
      'state': '',
      'country': '',
    });
  });

}

editDetails(){
this.successMsg = "";
this.isEditMode = true ;
}

get f() { return this.editProfile.controls; }
get f1() { return this.passwordForm.controls; }


isNewsLetter: any;

checkNewsLetter( event ) {
 
  this.isNewsLetter = event; 
}

initProfileData (id): void {

  let url =  "" ;

  if( this.loggedEntityType =='customer'){

    url += ServerURLS.getCustomerDetails.action;

  } else if (  this.loggedEntityType =='partner'){

    url += ServerURLS.getPartnerDetails.action;

  } else if (  this.loggedEntityType == 'user' || this.loggedEntityType =='admin' ){

    url += ServerURLS.getUserDetails.action;

  } 
  
  let promise1 = this.scHttpService.getHttpService( url +"?id="+id,'');
  promise1.then( value => {
    this.profileEditForm = value["data"];

    this.image = this.scCommonService.getLocalProfileImage();

    this.loggedUserAddrId = this.profileEditForm.defaultAddrId;
    if( this.loggedUserAddrId ){
      this.initAddresData(this.loggedUserAddrId);
    }
    
  });

}

updateProfile () {
  //  ;
  this.profileEditForm = this.editProfile.getRawValue();
 
  let url =  "" ;

    if(this.loggedUserId != null && this.loggedUserId != undefined){
      this.profileEditForm.uuid = this.loggedUserId;
    }

    if( this.loggedEntityType == 'customer'){

      url+= ServerURLS.updateCustomer.action;

    } else if (  this.loggedEntityType == 'partner'){

      url+= ServerURLS.updatePartner.action;

    } else if (this.loggedEntityType == 'user' || this.loggedEntityType =='admin'){

      url+= ServerURLS.updateUser.action;

    } 

  let authPromise = this.scHttpService.putHttpService(url, this.profileEditForm);

  authPromise.then( (resolve) => {
    let response = resolve;
    this.successMsg = response["message"];
    this.errMsg = "";
    this.initProfileData(this.loggedUserId);
    this.isEditMode = false ;
    
  }).catch((error) => {
    this.errMsg = error.message;
    this.successMsg ="";
  });

}


updatePassword () {

  let form_data = this.passwordForm.getRawValue();
 
   let authPromise = this.scHttpService.putHttpService(ServerURLS.changePassword.action, form_data);
 
   authPromise.then( (resolve) => {
     let response = resolve;
     this.successMsg = response["message"];
     this.router.navigateByUrl("/shipcarte/profilev2");
     
   }).catch((error) => {
     this.errMsg = error.message;
   });

 }

 cancel(){
  this.router.navigateByUrl("/shipcarte/profilev2");
 }

//Address component
get a() { return this.addressForm.controls; }

  editAddrDtls(){
    this.addrSuccessMsg = "";
    this.isEditAddrMode = true ;
  }

  initAddresData (id): void {

    let url =  ServerURLS.getAddressDetails.action;

    let promise1 = this.scHttpService.getHttpService( url +"?id="+id,'');
    promise1.then( value => {
      this.address = value["data"];
      this.addressForm.patchValue(this.address)

    });

  }

  saveAddress () {

    this.address = this.addressForm.getRawValue();

    if(this.loggedUserAddrId != null && this.loggedUserAddrId != undefined){
      this.address.addressId = this.loggedUserAddrId;
    }
  
   let authPromise = this.scHttpService.postHttpService(ServerURLS.saveAddress.action, this.address);
    
    authPromise.then( (resolve) => {
      let response = resolve;
      this.addrSuccessMsg = response["message"];
      this.addrErrMsg = "";
      this.initProfileData ( this.loggedUserId);
      this.isEditAddrMode = false ;
      
    }).catch((error) => {
      this.addrErrMsg = error.message;
      this.addrSuccessMsg ="";
    });
  }

  generatePdf(): void{
    
    const documentDefinition = { content: 'This is an sample PDF printed with pdfMake' };
    pdfMake.createPdf(documentDefinition).open();
    
  }

  saveProfileImage () {

    const formdata: FormData = new FormData();
    formdata.append('file', this.selectedFile);
    
    let url =  "" ;

      if( this.loggedEntityType == 'customer'){

        url+= ServerURLS.saveCustProfileImage.action;

      } else if (  this.loggedEntityType == 'partner'){

        url+= ServerURLS.savePartnerProfileImage.action;

      } else if (this.loggedEntityType == 'user' || this.loggedEntityType =='admin'){

        url+= ServerURLS.saveShipUserProfileImage.action;

      } 

    this.image = EnvironmentConstants.IMAGE_LOADER;
    let authPromise = this.scHttpService.postFileHttpService(url, formdata);

    authPromise.then( (resolve) => {
      let response = resolve;
      this.selectedFile = null;
      this.errMsg = "";

      if( response ){

        let fileId = response["data"]["fileId"];

        this.userData = this.scAuthService.getLoggedUserData();
        if( this.userData && fileId ){
          this.userData['fileId'] = fileId;
          this.storageService.saveBucket("entity", this.userData);
        }

        if( fileId ){
          let promise2 = this.scCommonService.getProfileImage( fileId);
          promise2.then( value => {
            this.image = this.scCommonService.getLocalProfileImage();
          });
          
        }

      }

    }).catch((error) => {
      this.errMsg = error.message;
      this.successMsg ="";
    });

  }

  onFileChanged(event) {

    if (event.target.files && event.target.files[0]) {
      this.selectedFile = event.target.files[0];
    }

    if( this.selectedFile ){
      this.saveProfileImage ();
    }
    
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}