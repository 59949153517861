import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as html2pdf from "html2pdf.js";
import { ScCommonService } from "src/app/services/common/sc-common.service";
import { ScHttpService } from "src/app/services/http/sc-http.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { LoadingService } from "src/app/services/loader.service";
import { ServerURLS } from "src/environments/environment";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-print-invoice-pdf",
  templateUrl: "./print-invoice-pdf.component.html",
  styleUrls: ["./print-invoice-pdf.component.scss"],
})
export class PrintInvoicePdfComponent implements OnInit {
  @Input() customerId;
  @Input() email;
  @Input() invoicePdfData;

  documentURL: any = null;
  applyNegativeCss: boolean = false;
  subTotal: any = 0.0;
  amountPaid: any = 0.0;
  remainingAmount: any = 0.0;

  constructor(
    private scHttpService: ScHttpService,
    public activeModal: NgbActiveModal,
    public common: ScCommonService,
    private toastr: ToastrService,
    private _loaderService: LoadingService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this._loaderService.stringSubject.next(true);
    if (this.invoicePdfData && this.invoicePdfData.invoiceOrderInfoList) {
      this.invoicePdfData.invoiceOrderInfoList.forEach((invoice) => {
        if (invoice && invoice.invoiceOrderInfoDetailsList) {
          invoice.invoiceOrderInfoDetailsList.forEach((record) => {
            this.remainingAmount += parseFloat(record.chargeInfo.dueAmount);
            this.subTotal += parseFloat(record.chargeInfo.netCharge);
            this.amountPaid += parseFloat(record.chargeInfo.paidAmount);
          });
        }
      });
      this.sss();
    } else {
      this._loaderService.stringSubject.next(false);
      this.closeModal();
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  sss() {
    setTimeout(() => {
      this.savePdf();
    }, 1000);
  }

  wait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  getPageBreakYes() {
    if (this.invoicePdfData.invoiceOrderInfoList.length > 1) {
      return true;
    }

    for (const order of this.invoicePdfData.invoiceOrderInfoList) {
      if (order.invoiceOrderInfoDetailsList.length > 1) {
        return true;
      }
    }

    return false;
  }

  getMarginClass() {
    return this.getPageBreakYes() ? "margin-negative" : "margin-zero";
  }

  async savePdf() {
    try {
      this.applyNegativeCss = true;
      await this.wait(200);
      let currentcomp = this;
      var element = document.querySelector("#contentInvoicePdf");
      const name = `INV_${
        currentcomp.invoicePdfData["invoiceNo"]
          ? currentcomp.invoicePdfData["invoiceNo"]
          : ""
      }.pdf`;
      var opt = {
        margin: 0.1,
        filename: name,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
          compressPDF: true,
        },
        pageBreak: { mode: "css", after: ".break-page" },
      };

      let pdf = await html2pdf().set(opt).from(element).toPdf().get("pdf");

      // Add page numbers
      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        pdf.setFontSize(10);
        pdf.text(
          `Page ${i} of ${totalPages}`,
          pageWidth - 1,
          pageHeight - 0.3,
          {
            align: "right",
          }
        );
      }

      // Output the PDF as a Blob
      const pdfBlob = await pdf.output("blob");

      // Send the PDF to the server
      currentcomp.sendPDFToServer(pdfBlob);
    } catch (err) {
      this._loaderService.stringSubject.next(false);
      this.closeModal();
    }
  }

  sendPDFToServer(pdfContent) {
    const formdata: FormData = new FormData();
    formdata.append("isCustomerEmailReq", this.email);
    formdata.append("customerId", this.customerId);
    let dummyFile = new File(
      [pdfContent],
      "INV_" +
        (this.invoicePdfData["invoiceNo"]
          ? this.invoicePdfData["invoiceNo"]
          : "") +
        ".pdf"
    );
    formdata.append("file", dummyFile);
    let url = ServerURLS.uploadInvoice.action;
    let promise1 = this.scHttpService.postFileHttpService(url, formdata);
    promise1
      .then((res: any) => {
        this._loaderService.stringSubject.next(false);
        if (res) {
          this.documentURL = this.sanitizer.bypassSecurityTrustResourceUrl(
            res.documentUrl
          );
        }
      })
      .catch((err) => {
        this._loaderService.stringSubject.next(false);
        this.toastr.error("Something went wrong!");
        this.closeModal();
      });
  }
}
