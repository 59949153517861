import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { ScHttpService } from '../../../services/http/sc-http.service'
import { ServerURLS } from '../../../../environments/environment'
import { Permission } from '../../commons/pojo/permission'
import { NgbDateParserFormatter, NgbDateStruct, NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { UnitedStatesDateParserFormatter } from 'src/app/services/common/sc-common.service'
import { LoadingService } from 'src/app/services/loader.service'

@Component({
  selector: 'app-partner-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: UnitedStatesDateParserFormatter }]
})
export class PartnerListComponent implements OnInit {
  displayData = false;
  partnerList: any
  activePage: number
  noOfPages: any
  startElementCount: any
  endElementCount: any
  permission: Permission
  errorMessage: string
  resetSuccessMsg: any
  isDataReady = false
  carriers: any
  choosenCarrier: any
  successMessage: any
  partnerId: any
  getCarriers: any = []
  model: any = {}
  displayRateUpload: Boolean = false
  excelUploadData: any = {
    carrier: '',
    file: [],
  }
  errorDisplayCarrier: Boolean = false
  errorDisplayFile: Boolean = false
  customMessageForUploaded = ''
  addSurchargeForm: FormGroup;
  modelFromDate: NgbDateStruct;
  modelEndDate: NgbDateStruct
  dateCompare: boolean;
  savePayload = []
  rateCarrierId: any;
  @ViewChild('loadContent', undefined) loadContent
  @ViewChild('uploadedModal', undefined) uploadedModal
  @ViewChild('popContentIntransit', undefined) createAddFuelSurchargePopUp;
  ltlRate: any;
  tlRate: any
  constructor(
    private scHttpService: ScHttpService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private config: NgbDatepickerConfig,
    private toaster: ToastrService,
    private  _loaderService: LoadingService
  ) {
    this.permission = new Permission()

    this.permission.read = true
    this.permission.write = true
    this.permission.action = true
  }


  // ngDoCheck(){
  //   console.log(this.getCarriers)
  //   this.getCarriers.forEach((element,index)=>{

  //     if(element.modelFromDate && element.modelEndDate.year){
  //        
  //       let startDate=Date.parse(element.modelFromDate.year+'-'+element.modelFromDate.month+'-'+element.modelFromDate.day);
  //       let endDate=Date.parse(element.modelEndDate.year+'-'+element.modelEndDate.month+'-'+element.modelEndDate.day);
  //       if(startDate>endDate){
  //         this.formErrors[index]=true
  //       }
  //     }
  //   })
  // }

  ngOnInit() {

    // this.addSurchargeForm = this.formBuilder.group({
    //   ltlRate: ['', [Validators.required]],
    //   tlRate: ['',[Validators.required]],
    // })
    this._loaderService.stringSubject.next(true);

    this.getCarriers.forEach(carrier => {
      carrier.modelFromDate = carrier.modelFromDate || { year: 0, month: 0, day: 0 };
      carrier.lastModifiedDate = carrier.lastModifiedDate || { year: 0, month: 0, day: 0 };
    });
    let startDate = this.activatedRoute.snapshot.paramMap.get("fromDate");
    if (startDate) {
      this.modelFromDate = { year: parseInt(startDate.split('-')[0]), month: parseInt(startDate.split('-')[1]), day: parseInt(startDate.split('-')[2]) }
    }
    this.config.minDate = { month: 1, day: 1, year: 1980, };
    this.config.outsideDays = 'hidden';
  }


  ngAfterViewInit() {
    this.loadPartnerList()
    this.loadCarriers()
    // this.getCarrierDetails()
    this.FuelSurchargeList();
    // this.rateUpload('hide');
  }



  rateUpload(toggle) {
    // console.log(toggle)
    if (toggle == 'hide') {
      this.displayRateUpload = false
    } else if (toggle == 'show') {
      this.excelUploadData.carrier = ''
      this.excelUploadData.file = []
      this.errorDisplayCarrier = false
      this.errorDisplayFile = false
      this.displayRateUpload = true
    }
  }

  // second tab functions *******************************************************************************************

  onChangeSelectCarrier(event) {
    // console.log(event);
    if (event.target.value.length > 0) {
      this.errorDisplayCarrier = false
      this.excelUploadData.carrier = event.target.value
    }
  }

  selectExcelFile(event) {
    event.preventDefault()
    let files
    if (event.dataTransfer) {
      files = event.dataTransfer.files
    } else if (event.target) {
      files = event.target.files
    }
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.errorDisplayFile = false
        this.excelUploadData.file = event.target.files[0]
      }
    }
    reader.readAsDataURL(files[0])
  }

  saveExcelUpload() {
    if (this.excelUploadData.carrier.length == 0) {
      this.errorDisplayCarrier = true
    } else if (this.excelUploadData.file.length == 0) {
      this.errorDisplayFile = true
    } else {
      let formData = new FormData()
      formData.append('file', this.excelUploadData.file)
      let urlForRateUpload =
        ServerURLS.rateUpload.action + '/' + this.excelUploadData.carrier
      // console.log(urlForRateUpload)
      let promiseRateUpload = this.scHttpService.postFileHttpService(
        urlForRateUpload,
        formData,
      )

      promiseRateUpload
        .then((res: any) => {
          this.displayData = false
          this._loaderService.stringSubject.next(false);
          if (res.status == 'Success') {
            this.customMessageForUploaded = 'File uploaded successfully'
            this.modalService.open(this.uploadedModal, {
              centered: true,
              backdropClass: 'light-blue-backdrop',
              windowClass: 'dark-modal',
              backdrop: 'static',
            })
          }
        })
        .catch((err) => {
          if (err.status == 'Failed') {
            this.customMessageForUploaded = err.message
            this.modalService.open(this.uploadedModal, {
              centered: true,
              backdropClass: 'light-blue-backdrop',
              windowClass: 'dark-modal',
              backdrop: 'static',
            })
          }
        })
    }
  }

  // second tab function end ******************************************************************************************************************

  getCarrierDetails() {
    let promise1 = this.scHttpService.getHttpService(
      ServerURLS.getCareerAll.action,
      '',
    )
    promise1.then((value) => {
      console.log(value)
      this.getCarriers = value
    })
  }

  FuelSurchargeList() {
    this.displayData = true
    this._loaderService.stringSubject.next(true);
    let promise1 = this.scHttpService.getHttpService(
      ServerURLS.FuelSurchargeList.action,
      '',
    )
    promise1.then((res: any) => {
      res.forEach((element, index) => {
        if (element.effStartDate) {
          res[index]['modelFromDate'] = { year: parseInt(element.effStartDate.split('-')[0]), month: parseInt(element.effStartDate.split('-')[1]), day: parseInt(element.effStartDate.split('-')[2]) };
        }
        if (element.lastModifiedDate) {
          res[index]['lastModifiedDate'] = { year: parseInt(element.lastModifiedDate.split('T')[0].split('-')[0]), month: parseInt(element.lastModifiedDate.split('T')[0].split('-')[1]), day: parseInt(element.lastModifiedDate.split('T')[0].split('-')[2]) }
        } else {
          var currentDate = new Date();

          // Get the current date
          var day = currentDate.getDate();
          var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
          var year = currentDate.getFullYear();

          // Output the current date
          console.log("Current Date: " + year + "-" + month + "-" + day);
          res[index]['lastModifiedDate']= {year:year,month:month,day:day}
        }
        // res[index]['modelEndDate']={year:parseInt(element.effEndDate.split('-')[0]),month:parseInt(element.effEndDate.split('-')[1]),day:parseInt(element.effEndDate.split('-')[2])};
      })
      console.log(res)
      this.getCarriers = res
      this.displayData = false
      this._loaderService.stringSubject.next(false);
    })
  }

  // save() {
  //   this.savePayload=[];
  //   const carriersWithDate = this.getCarriers.filter(carrier => 
  //     carrier.modelFromDate && carrier.modelFromDate.year !== undefined
  //   );

  //   carriersWithDate.forEach(carrier => {
  //     if(carrier.ltlRate==null || carrier.tlRate==null || carrier.modelFromDate==null){
  //       return;
  //     }  
  //       let formattedDate = `${carrier.modelFromDate.year}-${String(carrier.modelFromDate.month).padStart(2, '0')}-${String(carrier.modelFromDate.day).padStart(2, '0')}`;
  //       this.savePayload.push({
  //         "ltlRate": carrier.ltlRate,
  //         "tlRate": carrier.tlRate,
  //         "effStartDate": formattedDate,
  //         "carrierId": carrier.carrierId
  //       });

  //   });
  //   this.savePayload.forEach((ele)=>{
  //     if(ele.ltlRate==null || ele.tlRate==null || ele.modelFromDate==null){
  //       return;
  //     }     
  //   })

  //   if (this.savePayload.length > 0) {
  //     // Send the array of payloads in one HTTP request
  //     let promise2 = this.scHttpService.postHttpService(ServerURLS.fuelSurchargeRate.action, this.savePayload);
  //     promise2.then((res) => {
  //       this.displayData = false;
  //     });
  //   }
  // }

  formErrors: any[] = [];

  save() {
    this.savePayload = [];
    this.formErrors = []; // Reset formErrors array

    const carriersWithDate = this.getCarriers.filter(carrier =>
      carrier.modelFromDate && carrier.modelFromDate.year !== undefined
    );


    carriersWithDate.forEach((carrier, i) => {

      if (carrier.ltlRate == null || carrier.tlRate == null || carrier.modelFromDate == null) {
        // Set formErrors for the specific row and input fields
        this.formErrors[i] = {
          ltlRate: carrier.ltlRate == null,
          tlRate: carrier.tlRate == null,
          modelFromDate: carrier.modelFromDate == null,

        };
        return;
      }

      let formattedDate = `${carrier.modelFromDate.year}-${String(carrier.modelFromDate.month).padStart(2, '0')}-${String(carrier.modelFromDate.day).padStart(2, '0')}`;
      let formattedEndDate = `${carrier.lastModifiedDate.year}-${String(carrier.lastModifiedDate.month).padStart(2, '0')}-${String(carrier.lastModifiedDate.day).padStart(2, '0')}`;
      this.savePayload.push({
        "ltlRate": carrier.ltlRate,
        "tlRate": carrier.tlRate,
        "effStartDate": formattedDate,
        "carrierId": carrier.carrierId
      });
    });
    // Check if there are carriers with valid modelFromDate
    if (carriersWithDate.length >= 0) {
      // If no carriers with valid modelFromDate, set error for modelFromDate in formErrors for specific rows
      this.formErrors = []; // Clear the formErrors array first

      this.getCarriers.forEach((carrier, i) => {

        if ((carrier.ltlRate !== undefined || carrier.tlRate !== undefined) && carrier.modelFromDate === undefined) {
          this.formErrors[i] = {
            modelFromDate: true
          };
        } else if (carrier.modelFromDate !== undefined && carrier.ltlRate === undefined) {
          this.formErrors[i] = {
            ltlRate: true,
          };
        } else if (carrier.modelFromDate !== undefined && carrier.tlRate === undefined) {
          this.formErrors[i] = {
            tlRate: true,
          };
        }
        else {
          console.log(i)
          this.formErrors[i] = {
            modelFromDate: false,
            ltlRate: false,
            tlRate: false,
          };
        }
      });
    }
    console.log(this.formErrors)
    const hasErrors=this.formErrors.some(error=>error.modelFromDate || error.ltlRate || error.tlRate);
    console.log(hasErrors)
    if(!hasErrors){
      if (this.savePayload.length > 0) {
        // Send the array of payloads in one HTTP request
        let promise2 = this.scHttpService.postHttpService(ServerURLS.fuelSurchargeRate.action, this.savePayload);
        promise2.then((res) => {
          this.displayData = false;
          this._loaderService.stringSubject.next(false);
          
        }).catch((err:any)=>{
          
        })
        this.toaster.success("Date Updated.")
        
       
      }
    }
  }
  updateDate(date,i){
    // console.log(this.formErrors)
    this.getCarriers[i]['modelFromDate'] = date;
    // console.log(this.getCarriers)
    // if(date){
    //   if(this.formErrors[i]['modelFromDate'] && this.formErrors[i]['modelFromDate']==true)
    //   this.formErrors[i]['modelFromDate']=false;
    // }
  }
  loadPage(page: number) {
    if (page !== this.activePage) {
      this.loadPartnerList()
    }
  }

  page = 1
  pageSize = 5
  collectionSize = 5

  loadPartnerList(): void {
    this.loadCotent(this.loadContent)
    let url =
      ServerURLS.getPartnerList.action +
      '/' +
      (this.page - 1) +
      '/' +
      this.pageSize

    this.isDataReady = false
    let promise1 = this.scHttpService.getHttpService(url, '')

    promise1.then((value) => {
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      this.partnerList = value['data']['content']
      this.pageSize = value['data']['pageable']['pageSize']
      this.collectionSize = value['data']['totalElements']
      this.page = value['data']['pageable']['pageNumber'] + 1
      this.noOfPages = value['data']['totalPages']

      let offset = value['data']['pageable']['offset']
      this.startElementCount = offset + 1
      this.endElementCount = offset + value['data']['numberOfElements']

      this.activePage = this.page
      this.isDataReady = true
      this.cancel()
    })
  }

  editCarrier(data, index) {
    if (data.carrierId == '' || data.carrierMarkupRate == '') {
      alert('Please try again!')
      return
    }
    let inputData = {
      carrierId: data.carrierId,
      carrierMarkupRate: data.carrierMarkupRate,
    }
    let promise = this.scHttpService.postHttpService(
      ServerURLS.updateMarkup.action,
      inputData,
    )
    promise
      .then((response) => {

        if (response == null) {
          alert('Markup updated successfully!')
        } else {
          alert('Something went wrong,please try again!')
          return
        }
      })
      .catch((error) => {

      })
  }

  resetPassword(entityId) {
    let promise = this.scHttpService.putHttpService(
      ServerURLS.resetPasswordByAdmin.action + '?entityId=' + entityId,
      '',
    )

    promise.then((result) => {
      this.errorMessage = ''
      this.resetSuccessMsg = result['data']
    })

    promise.catch((result) => {
      this.errorMessage = result['message']
      this.resetSuccessMsg = ''
    })
  }

  loadCarriers(): void {
    let promise1 = this.scHttpService.getHttpService(
      ServerURLS.getAllCarriers.action,
      '',
    )

    promise1.then((value) => {
      console.log(value)
      this.carriers = value['data']
      console.log(value['data'], 'jhgh')
    })
  }

  open(content, partnerId) {
    this.successMessage = null
    this.errorMessage = null

    this.modalService.open(content, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
    })
    this.partnerId = partnerId
  }

  setCarrier(value) {
    this.choosenCarrier = value
  }

  associateCarrier() {
    let params =
      '?partner-id=' + this.partnerId + '&carrier-id=' + this.choosenCarrier
    let promise = this.scHttpService.postHttpService(
      ServerURLS.associatePartnerCarrier.action + params,
      '',
    )

    promise.then((result) => {
      this.errorMessage = ''

      if (result) {
        let response = result['data']
        this.successMessage = result['message']
        this.modalService.dismissAll()
      }
    })

    promise.catch((error) => {
      this.errorMessage = error['error']
      this.successMessage = ''
    })
  }

  cancel() {
    this.modalService.dismissAll()
  }

  loadCotent(loadContent) {
    // this.modalService.open(loadContent, {
    //   centered: true,
    //   backdropClass: 'light-blue-backdrop',
    //   windowClass: 'dark-modal',
    //   backdrop: 'static',
    // })
  }
}
