import { typeWithParameters } from "@angular/compiler/src/render3/util";
import { Injectable } from "@angular/core";
import { unwatchFile } from "fs";
import { BehaviorSubject, from } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ListDataServiceService {
  salesRepList: any;
  allOrdersData: any;
  standardChargeList: any;
  settingPackageManagerList: any;
  settingUserManagementData: any;
  allQuoteHistoryData: any;
  partnerMainList: any;
  customerList: any;
  allInvoiceList: any;
  toAuditInvoiceList: any;
  toInvoiceList: any;
  contractProcessingList: any;
  customerOrderHistory: any;
  partnerOrderHistory: any;
  salesRepAssociatedOrderList: any;
  salseRepAssociatedCustomerList: any;
  favoriteOrderList: any;
  isInvoiceSubmited= new BehaviorSubject(false);
  constructor() {
    this.salesRepList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      selectedId: "",
      searchkey: "",
    };
    this.allOrdersData = {
      allOrderList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      intransitList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      completedList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      failedList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      scheduledList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      tabId: 0,
    };
    this.standardChargeList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
    };
    this.settingPackageManagerList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searckKey: "",
    };
    this.settingUserManagementData = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      selectedId: "",
      searchKey: "",
      userDetailTabIsEnable: false,
    };
    this.allQuoteHistoryData = {
      allQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      draftQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      executedQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      failedQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      tabId: 0,
    };
    this.partnerMainList = {
      allOrder: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: false,
        sortParam: "",
      },
      activeOrders: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: false,
        sortParam: "",
      },
      inActiveOrder: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: false,
        sortParam: "",
      },
      tabId: 1,
    };
    this.customerList = {
      allCustomerList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        filterKey: "",
        isAscending: true,
        sortParam: "",
      },
      pendingCustomerList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: true,
        sortParam: "",
      },
      activeCustomerList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: true,
        sortParam: "",
      },
      inactiveCustomerList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: true,
        sortParam: "",
      },
      tabId: 0,
      formData: undefined,
    };
    this.allInvoiceList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
    };
    this.toAuditInvoiceList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
    };
    this.toInvoiceList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
    };
    this.contractProcessingList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
      selectedContractId: "",
    };
    this.customerOrderHistory = {
      pageCount: 20,
      pageSize: -1,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
      filterValue: "",
    };
    this.partnerOrderHistory = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
      filterValue: "",
    };
    this.salesRepAssociatedOrderList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
    };
    this.salseRepAssociatedCustomerList = {
      pageCount: -1,
      pageSizes: 20,
      dataList: [],
      searchKey: "",
      formData: null,
    };
    this.favoriteOrderList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
    };
  }
  //function for sales rep list from line 19 to 39
  setAllDataOfSalesRep(data: any): void {
    this.salesRepList.pageCount = data.pageCount;
    this.salesRepList.pageSize = data.pageSize;
    this.salesRepList.dataList = data.dataList;
  }
  setSalesRepId(id: any): void {
    this.salesRepList.selectedId = id;
  }
  setSalesRepSearchKey(key: any) {
    this.salesRepList.searchkey = key;
  }

  getSalesRepData() {
    return this.salesRepList;
  }
  removeAllSalesRepData() {
    this.salesRepList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      selectedId: "",
      searchkey: "",
    };
  }
  //function for order list from line 80 to 232
  setAllOrderListData(data: any, tabId: any): void {
    if (tabId == 1) {
      this.allOrdersData.allOrderList.pageCount = data.pageCount;
      this.allOrdersData.allOrderList.pageSize = data.pageSize;
      this.allOrdersData.allOrderList.dataList = data.dataList;
      this.allOrdersData.tabId = data.tabId;
    } else if (tabId == 2) {
      this.allOrdersData.intransitList.pageCount = data.pageCount;
      this.allOrdersData.intransitList.pageSize = data.pageSize;
      this.allOrdersData.intransitList.dataList = data.dataList;
      this.allOrdersData.tabId = data.tabId;
    } else if (tabId == 3) {
      this.allOrdersData.completedList.pageCount = data.pageCount;
      this.allOrdersData.completedList.pageSize = data.pageSize;
      this.allOrdersData.completedList.dataList = data.dataList;
      this.allOrdersData.tabId = data.tabId;
    } else if (tabId == 4) {
      this.allOrdersData.scheduledList.pageCount = data.pageCount;
      this.allOrdersData.scheduledList.pageSize = data.pageSize;
      this.allOrdersData.scheduledList.dataList = data.dataList;
      this.allOrdersData.tabId = data.tabId;
    } else if (tabId == 5) {
      this.allOrdersData.failedList.pageCount = data.pageCount;
      this.allOrdersData.failedList.pageSize = data.pageSize;
      this.allOrdersData.failedList.dataList = data.dataList;
      this.allOrdersData.tabId = data.tabId;
    }
  }

  setSelectedOrderId(id: any, tabId: any) {
    if (tabId == 1) {
      this.allOrdersData.allOrderList.selectedId = id;
    } else if (tabId == 2) {
      this.allOrdersData.intransitList.selectedId = id;
    } else if (tabId == 3) {
      this.allOrdersData.completedList.selectedId = id;
    } else if (tabId == 4) {
      this.allOrdersData.scheduledList.selectedId = id;
    } else if (tabId == 5) {
      this.allOrdersData.failedList.selectedId = id;
    }
  }
  setSearchkeyValueOfOrderId(value: any, tabId: any) {
    if (tabId == 1) {
      this.allOrdersData.allOrderList.searchkey = value;
    } else if (tabId == 2) {
      this.allOrdersData.intransitList.searchkey = value;
    } else if (tabId == 3) {
      this.allOrdersData.completedList.searchkey = value;
    } else if (tabId == 4) {
      this.allOrdersData.scheduledList.searchkey = value;
    } else if (tabId == 5) {
      this.allOrdersData.failedList.searchkey = value;
    }
  }
  setDataOfSortValueInOrderList(isAscending, sortParam, tabId) {
    if (tabId == 1) {
      this.allOrdersData.allOrderList.isAscending = isAscending;
      this.allOrdersData.allOrderList.sortParam = sortParam;
    } else if (tabId == 2) {
      this.allOrdersData.intransitList.isAscending = isAscending;
      this.allOrdersData.intransitList.sortParam = sortParam;
    } else if (tabId == 3) {
      this.allOrdersData.completedList.isAscending = isAscending;
      this.allOrdersData.completedList.sortParam = sortParam;
    } else if (tabId == 4) {
      this.allOrdersData.scheduledList.isAscending = isAscending;
      this.allOrdersData.scheduledList.sortParam = sortParam;
    } else if (tabId == 5) {
      this.allOrdersData.failedList.isAscending = isAscending;
      this.allOrdersData.failedList.sortParam = sortParam;
    }
  }
  setDateFilterValueOfOrderId(fromDate: any, toDate: any, tabId: any) {
    if (tabId == 1) {
      this.allOrdersData.allOrderList.fromDate = fromDate;
      this.allOrdersData.allOrderList.toDate = toDate;
    } else if (tabId == 2) {
      this.allOrdersData.intransitList.fromDate = fromDate;
      this.allOrdersData.intransitList.toDate = toDate;
    } else if (tabId == 3) {
      this.allOrdersData.completedList.fromDate = fromDate;
      this.allOrdersData.completedList.toDate = toDate;
    } else if (tabId == 4) {
      this.allOrdersData.scheduledList.fromDate = fromDate;
      this.allOrdersData.scheduledList.toDate = toDate;
    } else if (tabId == 5) {
      this.allOrdersData.failedList.fromDate = fromDate;
      this.allOrdersData.failedList.toDate = toDate;
    }
  }
  setDataListOfOrder(tabId: any) {
    if (tabId == 1) {
      this.allOrdersData.allOrderList.dataList = [];
    } else if (tabId == 2) {
      this.allOrdersData.intransitList.dataList = [];
    } else if (tabId == 3) {
      this.allOrdersData.completedList.dataList = [];
    } else if (tabId == 4) {
      this.allOrdersData.scheduledList.dataList = [];
    } else if (tabId == 5) {
      this.allOrdersData.failedList.dataList = [];
    }
  }
  setOrderListTabId(tabId: any) {
    this.allOrdersData.tabId = tabId;
  }
  getAllOrderListData() {
    return this.allOrdersData;
  }
  removeAllOrderListData() {
    this.allOrdersData = {
      allOrderList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      intransitList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      completedList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      scheduledList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      failedList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      tabId: 0,
    };
  }
  removeElementFromTheListAccordingToTabId(tabId, orderId) {
    if (tabId == 1) {
    } else if (tabId == 2) {
      let indexOfElement = -1;
      this.allOrdersData.intransitList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          indexOfElement = index;
        }
      });
      if (indexOfElement > -1) {
        this.allOrdersData.intransitList.dataList.splice(indexOfElement, 1);
      }
    } else if (tabId == 3) {
      let indexOfElement = -1;
      this.allOrdersData.completedList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          indexOfElement = index;
        }
      });
      if (indexOfElement > -1) {
        this.allOrdersData.completedList.dataList.splice(indexOfElement, 1);
      }
    } else if (tabId == 4) {
      //  
      let indexOfElement = -1;
      this.allOrdersData.scheduledList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          indexOfElement = index;
        }
      });
      if (indexOfElement > -1) {
        this.allOrdersData.scheduledList.dataList.splice(indexOfElement, 1);
      }
    } else if (tabId == 5) {
      //  
      let indexOfElement = -1;
      this.allOrdersData.failedList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          indexOfElement = index;
        }
      });
      if (indexOfElement > -1) {
        this.allOrdersData.failedList.dataList.splice(indexOfElement, 1);
      }
    }
  }
  removeDataOfTabWhenClickOnResetButton(tabId: any) {
    if (tabId == 1) {
      this.allOrdersData.allOrderList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      };
    } else if (tabId == 2) {
      this.allOrdersData.intransitList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      };
    } else if (tabId == 3) {
      this.allOrdersData.completedList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      };
    } else if (tabId == 4) {
      this.allOrdersData.scheduledList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      };
    } else if (tabId == 5) {
      this.allOrdersData.failedList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      };
    }
  }
  updateDataListOfOrderWhenChangeTheStatus(orderId, statusValue, tabId) {
    if (tabId == 1) {
      this.allOrdersData.allOrderList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.allOrdersData.allOrderList.dataList[index]["status"] =
            statusValue;
        }
      });
    }
    if (tabId == 2) {
      this.allOrdersData.intransitList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.allOrdersData.intransitList.dataList[index]["status"] =
            statusValue;
        }
      });
    }
    if (tabId == 3) {
      this.allOrdersData.completedList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.allOrdersData.completedList.dataList[index]["status"] =
            statusValue;
        }
      });
    }
    if (tabId == 4) {
      this.allOrdersData.scheduledList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.allOrdersData.scheduledList.dataList[index]["status"] =
            statusValue;
        }
      });
    }
    if (tabId == 5) {
      this.allOrdersData.failedList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.allOrdersData.failedList.dataList[index]["status"] = statusValue;
        }
      });
    }
  }

  //function for standard charges
  setDataofStandardChargeList(data: any) {
    this.standardChargeList.dataList = data.dataList;
    this.standardChargeList.pageCount = data.pageCount;
    this.standardChargeList.pageSize = data.pageSize;
  }
  getDataOfStandardChargelist() {
    return this.standardChargeList;
  }
  setSearchKeyOfStandardCharge(searchKey: any) {
    this.standardChargeList.searchKey = searchKey;
  }
  updateStandardChargeData(data) {
    this.standardChargeList.dataList.forEach((element, index) => {
      if (element.charge_id == data.charge_id) {
        this.standardChargeList.dataList[index] = data;
      }
    });
    console.log(this.standardChargeList);
  }
  removeStandardChargeItem(index) {
    this.standardChargeList.dataList.splice(index, 1);
  }
  removeAllDataOfStandardChargeList() {
    this.standardChargeList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
    };
  }

  //function for package manager
  setDataForSettingsPackageManagerList(data: any) {
    this.settingPackageManagerList.dataList = data.dataList;
    this.settingPackageManagerList.pageCount = data.pageCount;
    this.settingPackageManagerList.pageSize = data.pageSize;
  }
  getDataOfSettingPackageMansger() {
    return this.settingPackageManagerList;
  }
  setSearchKeyForSettingPackageManager(searckKey: any) {
    this.settingPackageManagerList.searckKey = searckKey;
  }
  updateDataInListOfSettingPackageManager(data: any) {
    console.log(data);
    this.settingPackageManagerList.dataList.forEach((element, index) => {
      if (element.package_id == data.packageId) {
        this.settingPackageManagerList.dataList[index]["package_name"] =
          data.packageName;
        this.settingPackageManagerList.dataList[index]["package_description"] =
          data.packageDescription;
        this.settingPackageManagerList.dataList[index]["style_code"] =
          data.styleCode;
        this.settingPackageManagerList.dataList[index][
          "package_dimension"
        ].length = data.length;
        this.settingPackageManagerList.dataList[index][
          "package_dimension"
        ].height = data.height;
        this.settingPackageManagerList.dataList[index][
          "package_dimension"
        ].width = data.width;
        this.settingPackageManagerList.dataList[index][
          "package_dimension"
        ].weight = data.weight;
      }
    });
  }
  removeDataInListOfSettingPackageManager(packageId: any) {
    let itemIndex = -1;
    this.settingPackageManagerList.dataList.forEach((elemnt, index) => {
      if (elemnt.package_id == packageId) {
        itemIndex = index;
      }
    });
    this.settingPackageManagerList.dataList.splice(itemIndex, 1);
  }
  removeAllDataOfSettingpackageManageer() {
    this.settingPackageManagerList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searckKey: "",
    };
  }
  //function for setting user management
  setDataForSettingUserManagementList(data: any) {
    this.settingUserManagementData.pageCount = data.pageCount;
    this.settingUserManagementData.pageSize = data.pageSize;
    this.settingUserManagementData.dataList = data.dataList;
  }
  getDataOfSettingUserManagementList() {
    return this.settingUserManagementData;
  }
  setSearchKeyForSettingUserManagmentList(searckKey: any) {
    this.settingUserManagementData.searchKey = searckKey;
  }
  setSelectedIdForSettingUserManagmentList(id: any) {
    this.settingUserManagementData.selectedId = id;
  }
  setValueForUserDetailIsEnableValue(value) {
    this.settingUserManagementData.userDetailTabIsEnable = value;
  }
  getValueForUserDetailIsEnableValue() {
    return this.settingUserManagementData.userDetailTabIsEnable;
  }
  removeAllDataSettingUserManagement() {
    this.settingUserManagementData = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      selectedId: "",
      searchKey: "",
      userDetailTabIsEnable: false,
    };
  }

  //function for setting admin management
  setDataForSettingAdminManagementList(data: any) {
    this.settingUserManagementData.pageCount = data.pageCount;
    this.settingUserManagementData.pageSize = data.pageSize;
    this.settingUserManagementData.dataList = data.dataList;
  }
  getDataOfSettingAdminManagementList() {
    return this.settingUserManagementData;
  }
  setSearchKeyForSettingAdminManagmentList(searckKey: any) {
    this.settingUserManagementData.searchKey = searckKey;
  }
  setSelectedIdForSettingAdminManagmentList(id: any) {
    this.settingUserManagementData.selectedId = id;
  }
  setValueForAdminDetailIsEnableValue(value) {
    this.settingUserManagementData.userDetailTabIsEnable = value;
  }
  getValueForAdminDetailIsEnableValue() {
    return this.settingUserManagementData.userDetailTabIsEnable;
  }
  removeAllDataSettingAdminManagement() {
    this.settingUserManagementData = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      selectedId: "",
      searchKey: "",
      userDetailTabIsEnable: false,
    };
  }

  //function for quote history
  setAllDataOfQuoteHistory(data, tabId) {
    if (tabId == 1) {
      this.allQuoteHistoryData.allQuotes.pageCount = data.pageCount;
      this.allQuoteHistoryData.allQuotes.pageSize = data.pageSize;
      this.allQuoteHistoryData.allQuotes.dataList = data.dataList;
      this.allQuoteHistoryData.tabId = tabId;
    } else if (tabId == 2) {
      this.allQuoteHistoryData.draftQuotes.pageCount = data.pageCount;
      this.allQuoteHistoryData.draftQuotes.pageSize = data.pageSize;
      this.allQuoteHistoryData.draftQuotes.dataList = data.dataList;
      this.allQuoteHistoryData.tabId = tabId;
    } else if (tabId == 3) {
      this.allQuoteHistoryData.executedQuotes.pageCount = data.pageCount;
      this.allQuoteHistoryData.executedQuotes.pageSize = data.pageSize;
      this.allQuoteHistoryData.executedQuotes.dataList = data.dataList;
      this.allQuoteHistoryData.tabId = tabId;
    } else if (tabId == 4) {
      this.allQuoteHistoryData.failedQuotes.pageCount = data.pageCount;
      this.allQuoteHistoryData.failedQuotes.pageSize = data.pageSize;
      this.allQuoteHistoryData.failedQuotes.dataList = data.dataList;
      this.allQuoteHistoryData.tabId = tabId;
    }
  }
  getAllDataOfQuoteHistory() {
    return this.allQuoteHistoryData;
  }
  setSearchKeyForQuoteHistory(searchKey, TabId) {
    if (TabId == 1) {
      this.allQuoteHistoryData.allQuotes.searchkey = searchKey;
    } else if (TabId == 2) {
      this.allQuoteHistoryData.draftQuotes.searchkey = searchKey;
    } else if (TabId == 3) {
      this.allQuoteHistoryData.executedQuotes.searchkey = searchKey;
    } else if (TabId == 4) {
      this.allQuoteHistoryData.failedQuotes.searchkey = searchKey;
    }
  }
  setFromDateToDateOfQuoteHistory(fromDate, toDate, tabId) {
    if (tabId == 1) {
      this.allQuoteHistoryData.allQuotes.fromDate = fromDate;
      this.allQuoteHistoryData.allQuotes.toDate = toDate;
    } else if (tabId == 2) {
      this.allQuoteHistoryData.draftQuotes.fromDate = fromDate;
      this.allQuoteHistoryData.draftQuotes.toDate = toDate;
    } else if (tabId == 3) {
      this.allQuoteHistoryData.executedQuotes.fromDate = fromDate;
      this.allQuoteHistoryData.executedQuotes.toDate = toDate;
    } else if (tabId == 4) {
      this.allQuoteHistoryData.failedQuotes.fromDate = fromDate;
      this.allQuoteHistoryData.failedQuotes.toDate = toDate;
    }
  }
  setSelectedItemIdForQuoteHistory(id, tabId) {
    if (tabId == 1) {
      this.allQuoteHistoryData.allQuotes.selectedId = id;
    } else if (tabId == 2) {
      this.allQuoteHistoryData.draftQuotes.selectedId = id;
    } else if (tabId == 3) {
      this.allQuoteHistoryData.executedQuotes.selectedId = id;
    } else if (tabId == 4) {
      this.allQuoteHistoryData.failedQuotes.selectedId = id;
    }
  }
  setTabIdForQuoteHistory(tabId) {
    this.allQuoteHistoryData.tabId = tabId;
  }
  removeAllDataOfQuoteHistory() {
    this.allQuoteHistoryData = {
      allQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      draftQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      executedQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      failedQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      },
      tabId: 0,
    };
  }
  setDataOfSortParamInQuoteHistory(isAscending, sortParam, tabId) {
    if (tabId == 1) {
      this.allQuoteHistoryData.allQuotes.isAscending = isAscending;
      this.allQuoteHistoryData.allQuotes.sortParam = sortParam;
    } else if (tabId == 2) {
      this.allQuoteHistoryData.draftQuotes.isAscending = isAscending;
      this.allQuoteHistoryData.draftQuotes.sortParam = sortParam;
    } else if (tabId == 3) {
      this.allQuoteHistoryData.executedQuotes.isAscending = isAscending;
      this.allQuoteHistoryData.executedQuotes.sortParam = sortParam;
    } else if (tabId == 4) {
      this.allQuoteHistoryData.failedQuotes.isAscending = isAscending;
      this.allQuoteHistoryData.failedQuotes.sortParam = sortParam;
    }
  }
  removeDataOfTabWhenClickOnResetButtonQuoteHistory(tabId) {
    if (tabId == 1) {
      this.allQuoteHistoryData.allQuotes = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      };
    } else if (tabId == 2) {
      this.allQuoteHistoryData.draftQuotes = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      };
    } else if (tabId == 3) {
      this.allQuoteHistoryData.executedQuotes = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      };
    } else if (tabId == 4) {
      this.allQuoteHistoryData.failedQuotes = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: "",
        searchkey: "",
        fromDate: "",
        toDate: "",
        isAscending: false,
        sortParam: "",
      };
    }
  }

  //function for partner main list
  setAllDataOfPartnerMainList(data, tabId) {
    if (tabId == 1) {
      this.partnerMainList.allOrder.pageCount = data.pageCount;
      this.partnerMainList.allOrder.pageSize = data.pageSize;
      this.partnerMainList.allOrder.dataList = data.dataList;
    } else if (tabId == 3) {
      this.partnerMainList.activeOrders.pageCount = data.pageCount;
      this.partnerMainList.activeOrders.pageSize = data.pageSize;
      this.partnerMainList.activeOrders.dataList = data.dataList;
    } else if (tabId == 4) {
      this.partnerMainList.inActiveOrder.pageCount = data.pageCount;
      this.partnerMainList.inActiveOrder.pageSize = data.pageSize;
      this.partnerMainList.inActiveOrder.dataList = data.dataList;
    }
  }
  getAllDataOfPartnerList() {
    return this.partnerMainList;
  }
  setSearchKeyOfPartnerList(searchKey, tabId) {
    if (tabId == 1) {
      this.partnerMainList.allOrder.searchkey = searchKey;
    } else if (tabId == 3) {
      this.partnerMainList.activeOrders.searchkey = searchKey;
    } else if (tabId == 4) {
      this.partnerMainList.inActiveOrder.searchkey = searchKey;
    }
  }
  setSelectedItemIdOfPartnerList(id, tabId) {
    if (tabId == 1) {
      this.partnerMainList.allOrder.selectedId = id;
    } else if (tabId == 3) {
      this.partnerMainList.activeOrders.selectedId = id;
    } else if (tabId == 4) {
      this.partnerMainList.inActiveOrder.selectedId = id;
    }
  }
  setTabIdOfPartnerList(tabId) {
    this.partnerMainList.tabId = tabId;
  }
  updateStatusOfPartnerActiveInActive(statusValue, partnerId) {
    this.partnerMainList.allOrder.dataList.forEach((element, index) => {
      if (element.carrier_id == partnerId) {
        this.partnerMainList.allOrder.dataList[index]["status"] = statusValue;
      }
    });
  }
  setDataOfSortValueInPartnerList(isAscending, sortParam, tabId) {
    if (tabId == 1) {
      this.partnerMainList.allOrder.isAscending = isAscending;
      this.partnerMainList.allOrder.sortParam = sortParam;
    } else if (tabId == 3) {
      this.partnerMainList.activeOrders.isAscending = isAscending;
      this.partnerMainList.activeOrders.sortParam = sortParam;
    } else if (tabId == 4) {
      this.partnerMainList.inActiveOrder.isAscending = isAscending;
      this.partnerMainList.inActiveOrder.sortParam = sortParam;
    }
  }
  refreshPartnerListDataAccordingToTabId(tabId) {
    if (tabId == 1) {
      this.partnerMainList.allOrder = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: false,
        sortParam: "",
      };
    } else if (tabId == 3) {
      this.partnerMainList.activeOrders = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: false,
        sortParam: "",
      };
    } else if (tabId == 4) {
      this.partnerMainList.inActiveOrder = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: false,
        sortParam: "",
      };
    }
  }
  removeAllDataOfPartnerList() {
    this.partnerMainList = {
      allOrder: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: false,
        sortParam: "",
      },
      activeOrders: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: false,
        sortParam: "",
      },
      inActiveOrder: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: false,
        sortParam: "",
      },
      tabId: 1,
    };
  }

  // function for customer main list
  setAllDataCustomerMainList(data, tabId) {
    if (tabId == 1) {
      this.customerList.allCustomerList.dataList = data.dataList;
      this.customerList.allCustomerList.pageCount = data.pageCount;
      this.customerList.allCustomerList.pageSize = data.pageSize;
    } else if (tabId == 2) {
      this.customerList.pendingCustomerList.dataList = data.dataList;
      this.customerList.pendingCustomerList.pageCount = data.pageCount;
      this.customerList.pendingCustomerList.pageSize = data.pageSize;
    } else if (tabId == 3) {
      this.customerList.activeCustomerList.dataList = data.dataList;
      this.customerList.activeCustomerList.pageCount = data.pageCount;
      this.customerList.activeCustomerList.pageSize = data.pageSize;
    } else if (tabId == 4) {
      this.customerList.inactiveCustomerList.dataList = data.dataList;
      this.customerList.inactiveCustomerList.pageCount = data.pageCount;
      this.customerList.inactiveCustomerList.pageSize = data.pageSize;
    }
    this.customerList.tabId = tabId;
  }
  setDataOfFormDataOfCustomerList(data) {
    this.customerList.formData = data;
  }
  setTabIdCustomerMainList(tabId) {
    this.customerList.tabId = tabId;
  }
  setSearchKeyOfCustomerMainList(searchkey, tabId) {
    if (tabId == 1) {
      this.customerList.allCustomerList.searchkey = searchkey;
    } else if (tabId == 2) {
      this.customerList.pendingCustomerList.searchkey = searchkey;
    } else if (tabId == 3) {
      this.customerList.activeCustomerList.searchkey = searchkey;
    } else if (tabId == 4) {
      this.customerList.inactiveCustomerList.searchkey = searchkey;
    }
  }
  setFilterValueOfCustomerMainList(filterValue) {
    this.customerList.allCustomerList.filterKey = filterValue;
  }
  setDataOfSortValueInCustomerList(isAscending, sortParam, tabId) {
    if (tabId == 1) {
      this.customerList.allCustomerList.isAscending = isAscending;
      this.customerList.allCustomerList.sortParam = sortParam;
    } else if (tabId == 2) {
      this.customerList.pendingCustomerList.isAscending = isAscending;
      this.customerList.pendingCustomerList.sortParam = sortParam;
    } else if (tabId == 3) {
      this.customerList.activeCustomerList.isAscending = isAscending;
      this.customerList.activeCustomerList.sortParam = sortParam;
    } else if (tabId == 4) {
      this.customerList.inactiveCustomerList.isAscending = isAscending;
      this.customerList.inactiveCustomerList.sortParam = sortParam;
    }
  }
  updatedTheValueOfSelectedCustomerInCustomerList(id, tabId) {
    if (tabId == 1) {
      this.customerList.allCustomerList.selectedId = id;
    } else if (tabId == 2) {
      this.customerList.pendingCustomerList.selectedId = id;
    } else if (tabId == 3) {
      this.customerList.activeCustomerList.selectedId = id;
    } else if (tabId == 4) {
      this.customerList.inactiveCustomerList.selectedId = id;
    }
  }
  updateStatusOfCustomerMainList(statusValue, customerId) {
    this.customerList.allCustomerList.dataList.forEach((element, index) => {
      if (element.customer_id == customerId) {
        this.customerList.allCustomerList.dataList[index]["status"] =
          statusValue;
      }
    });
  }
  refreshTheDataOfCustomerListsWhenChangeTheStatus(tabId) {
    if (tabId == 3) {
      this.customerList.activeCustomerList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: true,
        sortParam: "",
      };
    } else if (tabId == 4) {
      this.customerList.inactiveCustomerList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: true,
        sortParam: "",
      };
    }
  }
  removeElementFromtheCustomerListByIndex(tabid, customerId) {
    if (tabid == 3) {
      let indexOfElement = -1;
      this.customerList.activeCustomerList.dataList.forEach(
        (element, index) => {
          if (element.customer_id == customerId) {
            indexOfElement = index;
          }
        }
      );
      if (indexOfElement > -1) {
        this.customerList.activeCustomerList.dataList.splice(indexOfElement, 1);
      }
    } else if (tabid == 4) {
      let indexOfElement = -1;
      this.customerList.inactiveCustomerList.dataList.forEach(
        (element, index) => {
          if (element.customer_id == customerId) {
            indexOfElement = index;
          }
        }
      );
      if (indexOfElement > -1) {
        this.customerList.inactiveCustomerList.dataList.splice(
          indexOfElement,
          1
        );
      }
    }
  }
  getAllDataOfCustomerList() {
    return this.customerList;
  }
  removeAllDataOfCustomersList() {
    this.customerList = {
      allCustomerList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        filterKey: "",
        isAscending: true,
        sortParam: "",
      },
      pendingCustomerList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: true,
        sortParam: "",
      },
      activeCustomerList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: true,
        sortParam: "",
      },
      inactiveCustomerList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchkey: "",
        selectedId: "",
        isAscending: true,
        sortParam: "",
      },
      tabId: 0,
      formData: undefined,
    };
  }

  setAllDataOfInvoiceList(data) {
    this.allInvoiceList.pageCount = data.pageCount;
    this.allInvoiceList.pageSize = data.pageSize;
    this.allInvoiceList.dataList = data.dataList;
  }
  setSearchkeyForInvoiceList(value) {
    this.allInvoiceList.searchKey = value;
  }
  setFromDateToDateValueOfSearchKeyOfInvoiceList(fromDate, toDate) {
    this.allInvoiceList.fromDate = fromDate;
    this.allInvoiceList.toDate = toDate;
  }
  getAllDataOfAllInvoiceList() {
    return this.allInvoiceList;
  }
  // removeDateFromAllInvoiceList(){
  //   this.allInvoiceList={
  //     pageCount:-1,
  //     pageSize:20,
  //     dataList:[],
  //     searchKey:'',
  //     fromDate:'',
  //     toDate:''
  //   }
  // }
  removeAllDataOfInvoiceList() {
    this.allInvoiceList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
    };
  }

  //function for to audit list
  setAllDataOfToAuditInvoiceList(data) {
    this.toAuditInvoiceList.dataList = data.dataList;
    this.toAuditInvoiceList.pageSize = data.pageSize;
    this.toAuditInvoiceList.pageCount = data.pageCount;
  }
  setSearchKeyOfToAuditInvoiceList(value) {
    this.toAuditInvoiceList.searchKey = value;
  }
  setFromDateToDateOfToAuditInvoiceList(fromDate, toDate) {
    this.toAuditInvoiceList.fromDate = fromDate;
    this.toAuditInvoiceList.toDate = toDate;
  }
  getAllDataOfToAuditInvoiceList() {
    return this.toAuditInvoiceList;
  }
  removeAllDataOfToAuditInvoiceList() {
    this.toAuditInvoiceList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
    };
  }
  //function for to invoie list
  setAllDataOfToInvoiceList(data) {
    this.toInvoiceList.dataList = data.dataList;
    this.toInvoiceList.pageCount = data.pageCount;
    this.toInvoiceList.pageSize = data.pageSize;
  }
  setSearchKeyOfToInvoiceList(value) {
    this.toInvoiceList.searchKey = value;
  }
  setFromDateToDateOfToInvoiceList(fromDate, toDate) {
    this.toInvoiceList.fromDate = fromDate;
    this.toInvoiceList.toDate = toDate;
  }
  getAllDataOfToInvoiceList() {
    return this.toInvoiceList;
  }
  removeAllDataOfToInvoiceList() {
    this.toInvoiceList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
    };
  }

  // function for to contact processing list
  setAllDataOfContactProcessingList(data) {
    this.contractProcessingList.dataList = data.dataList;
    this.contractProcessingList.pageCount = data.pageCount;
    this.contractProcessingList.pageSize = data.pageSize;
  }
  setSearchKeyOfContractProcessingList(value) {
    this.contractProcessingList.searchKey = value;
  }
  setFromDateToDateOfContractProcessingList(fromDate, toDate) {
    this.contractProcessingList.fromDate = fromDate;
    this.contractProcessingList.toDate = toDate;
  }
  setSelectedContactIdInContactProcessingList(id) {
    this.contractProcessingList.selectedContractId = id;
  }
  getAllDataOfContractProcessingList() {
    return this.contractProcessingList;
  }
  removeAllDataOfToContractProcessingList() {
    this.contractProcessingList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
      selectedContractId: "",
    };
  }

  // function for customer order history
  setAllDataOfCustomerOrderHistory(data) {
    this.customerOrderHistory.dataList = data.dataList;
    this.customerOrderHistory.pageCount = data.pageCount;
    this.customerOrderHistory.pageSize = data.pageSize;
  }

  setFilterValueOfCustomerOrderHistory(value) {
    this.customerOrderHistory.filterValue = value;
  }

  setSearchKeyOfCustomerOrderHistory(value) {
    this.customerOrderHistory.searchKey = value;
  }

  setFromDateToDateValueOfCustomerOrderHistory(fromDate, toDate) {
    this.customerOrderHistory.fromDate = fromDate;
    this.customerOrderHistory.toDate = toDate;
  }

  getAllDataOfCustomerOrderHistory() {
    return this.customerOrderHistory;
  }
  removeAllDataOfCustomerOrderHistory() {
    this.customerOrderHistory = {
      pageCount: 20,
      pageSize: -1,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
      filterValue: "",
    };
  }

  //function for partner order history
  setAllDataOfPartnerOrderHistory(data) {
    this.partnerOrderHistory.dataList = data.dataList;
    this.partnerOrderHistory.pageCount = data.pageCount;
    this.partnerOrderHistory.pageSize = data.pageSize;
  }
  setFilterValueOfPartnerOrderHistory(value) {
    this.partnerOrderHistory.filterValue = value;
  }
  setSearchKeyOfPartnerOrderHistory(value) {
    this.partnerOrderHistory.searchKey = value;
  }
  setFromDateToDateValueOfPartnerOrderHistory(fromDate, toDate) {
    this.partnerOrderHistory.fromDate = fromDate;
    this.partnerOrderHistory.toDate = toDate;
  }
  getAllDataOfPartnerOrderHistory() {
    return this.partnerOrderHistory;
  }
  removeAllDataOfPartnerOrderHistory() {
    this.partnerOrderHistory = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
      filterValue: "",
    };
  }
  //fucntion for salse associated order list
  setAllDataOfSalesRepAssociatedOrder(data) {
    this.salesRepAssociatedOrderList.pageCount = data.pageCount;
    this.salesRepAssociatedOrderList.pageSize = data.pageSize;
    this.salesRepAssociatedOrderList.dataList = data.dataList;
  }
  setSearchKeyForSalesRepAssociatedOrder(value) {
    this.salesRepAssociatedOrderList.searchKey = value;
  }
  setFromDateToDateValueofSalesRepAssociatedOrderList(fromDate, toDate) {
    this.salesRepAssociatedOrderList.fromDate = fromDate;
    this.salesRepAssociatedOrderList.toDate = toDate;
  }
  getAllDataOfSalesRepAssociatedOrderList() {
    return this.salesRepAssociatedOrderList;
  }
  removeAllDataOfSalesRepAssociatedOrderList() {
    this.salesRepAssociatedOrderList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
      fromDate: "",
      toDate: "",
    };
  }
  //fucntion for sales rep associated customer list
  setAllDataOfSalesRepAssocaitedCustomerList(data) {
    this.salseRepAssociatedCustomerList.pageCount = data.pageCount;
    this.salseRepAssociatedCustomerList.pageSize = data.pageSize;
    this.salseRepAssociatedCustomerList.dataList = data.dataList;
  }
  setSearchkeyForSalesRepAssociatedCustomerList(value) {
    this.salseRepAssociatedCustomerList.searchKey = value;
  }
  setFromDataForSalesRepAssociatedCustomerList(data) {
    this.salseRepAssociatedCustomerList.formData = data;
  }
  getAllDataOfSalesRepAssociatedCustomerlist() {
    return this.salseRepAssociatedCustomerList;
  }
  removeAllDataOfSalesRepAssociatedCustomerList() {
    this.salseRepAssociatedCustomerList = {
      pageCount: -1,
      pageSizes: 20,
      dataList: [],
      searchKey: "",
    };
  }
  //fucntion for favorite order list
  setAllDataOfFavoriteOrderlist(data) {
    this.favoriteOrderList.pageCount = data.pageCount;
    this.favoriteOrderList.pageSize = data.pageSize;
    this.favoriteOrderList.dataList = data.dataList;
  }
  setSearchKeyForFavoriteOrderList(value) {
    this.favoriteOrderList.searchKey = value;
  }
  getAllDataOfFavoriteOrderList() {
    return this.favoriteOrderList;
  }
  removeAllDataOfFavoriteOrderList() {
    this.favoriteOrderList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: "",
    };
  }
  removeAllListData() {
    this.removeAllOrderListData();
    this.removeAllDataOfCustomersList();
    this.removeAllDataOfPartnerList();
    this.removeAllDataOfQuoteHistory();
    this.removeAllDataOfStandardChargeList();
    this.removeAllDataOfSettingpackageManageer();
    this.removeAllDataSettingUserManagement();
    this.removeAllDataOfInvoiceList();
    this.removeAllDataOfToAuditInvoiceList();
    this.removeAllDataOfToContractProcessingList();
    this.removeAllDataOfCustomerOrderHistory();
    this.removeAllSalesRepData();
    this.removeAllDataOfPartnerOrderHistory();
    this.removeAllDataOfSalesRepAssociatedOrderList();
    this.removeAllDataOfSalesRepAssociatedCustomerList();
    this.removeAllDataOfFavoriteOrderList();
  }
}
