import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import * as jspdf from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import { formatDate } from '@angular/common';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EmailDocumentComponent } from '../email-document/email-document.component';
import { DocumentService } from 'src/app/services/document.service';
import { LoadingService } from 'src/app/services/loader.service';
import { ServerURLS } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
import { PrintInvoicePdfComponent } from '../print-invoice-pdf/print-invoice-pdf.component';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  @Input() data;
  @Input() quoteIdForEmail;
  @Input() showIcons;
  @Input() showSubmitInvoice : Boolean = false;
  @Input() listOrderId;
  @Input() orderId;
  @Input() customerId;
  @Input() date;
  @Input() email;
  @Input() type;

  documentIdForEmail: any;
  printBOL: boolean;
  formattedDate:any;
  charges:any;
  invoicePdfData: any;
  isSubmitRequestProcessing:boolean=false;
  applyNegativeCss:boolean = false;
  subTotal:any=0.0;
  amountPaid:any=0.0;
  remainingAmount:any=0.0;

  constructor(
    private scHttpService: ScHttpService, 
    public activeModal: NgbActiveModal,
    public common : ScCommonService, 
    private toastr: ToastrService, 
    private modalService: NgbModal,
    private router: Router,
    private docService: DocumentService,
    private  _loaderService: LoadingService,
    private listDataService:ListDataServiceService,
  ) { }

  ngOnInit() {
    // let url = 'https://shipcarte2.ebizontech.biz/shipcarte-backend/shipcarte/api/invoices/getById?orderId=651288f2-0aee-44d9-8920-799d2fb034da';
    // this.scHttpService.getHttpService(url, {withCredentials: true}).then((res)=>{
    //   console.log(res)
    //    
    // }).catch((err)=>{
    //   console.log(err)
    // })
    if(this.date){
     this.date = new Date(this.date.year + "-" + this.date.month + "-" + this.date.day);
    }
    this._loaderService.stringSubject.next(true);
    if(this.quoteIdForEmail){
    this.docService.getData(this.quoteIdForEmail).subscribe(result=>{
      if (result) {
      this._loaderService.stringSubject.next(false);
      }
      this.documentIdForEmail=result['documentId'];
    }, error => {
      this._loaderService.stringSubject.next(false);
      if(error.status==401){
        this.router.navigate(['/signin']);
      }
      this.toastr.error(error['error']["message"],'Please try again.', {
        timeOut: 10000,
      });
    });
    }

    this.getInvoicePdfData()

    this.printBOL = true;
 
    if(this.common.printInvoiceFromInvoices === true){
      this.sss();
      this.common.printInvoiceFromInvoices= false;
    }
    
    var charges = [];
    this.data.chargeComponents.charges.forEach((item, index) => {
      let keys = Object.keys(item);
      let chargeKey = keys.find(key => key !== 'isAdjust');
      let isAdjust = item['isAdjust'];
  
      if (chargeKey) {
          charges.push({
              charge: [chargeKey, item[chargeKey]],
              isAdjust: isAdjust
          });
      }
  });
  
  this.charges = charges;
    this.formattedDate = formatDate(this.data.orderDate, 'dd/MM/yyyy', 'en-US');
  }


  getInvoicePdfData(){
    this._loaderService.stringSubject.next(true);
    console.log(this.listOrderId,"before")
    this.docService.getInvoicePdfData("",this.listOrderId).subscribe((result:any)=>{

      
      if (result) {
      this._loaderService.stringSubject.next(false);

      this.invoicePdfData=result;
      if(result && result.invoiceOrderInfoList){
        result.invoiceOrderInfoList.forEach((invoice)=> {
          if(invoice && invoice.invoiceOrderInfoDetailsList){
            invoice.invoiceOrderInfoDetailsList.forEach((record)=>{
              this.remainingAmount+= parseFloat(record.chargeInfo.dueAmount);
              this.subTotal+= parseFloat(record.chargeInfo.netCharge);
              this.amountPaid+= parseFloat(record.chargeInfo.paidAmount);
            })
          }
        })
      }

      }
    }, error => {
      this._loaderService.stringSubject.next(false);
      if(error.status==401){
        this.router.navigate(['/signin']);
      }
      this.toastr.error(error['error']["message"],'Please try again.', {
        timeOut: 10000,
      });
    });

  }
  closeModal() { this.activeModal.close(); }

  print(doc?){
 
    if(doc=='bol'){
      this.printBOL = true;
    }
    setTimeout(() => {
      // window.print();
      const modalRef = this.modalService.open(PrintInvoicePdfComponent, { size: 'lg', centered: true, backdrop: true, windowClass: "my-custom-modal9 print-pdf" });
      modalRef.componentInstance.invoicePdfData = this.invoicePdfData;
      modalRef.componentInstance.email = this.email;
      modalRef.componentInstance.customerId = this.customerId;
      modalRef.result.then((result) => {
        // this.close();
      });
    }, 1000);
  }

  sss(){
    setTimeout(() => {
      window.print();
    }, 1000);
  }

  wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  async downloadInvoice() {
    this.applyNegativeCss = true; 
    this._loaderService.stringSubject.next(true);
    await this.wait(200);
    let compData = this;
    var element = document.querySelector('#contentInvoice');
    const name = `INV_${compData.invoicePdfData["invoiceNo"] ? compData.invoicePdfData["invoiceNo"] : ""}.pdf`;
    
    var opt = {
        margin: 0.1,
        filename: name,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
        pagebreak: { mode: ['css', 'legacy'], after: ['.break-page'] },
    };

    // Generate the PDF
    html2pdf()
        .set(opt)
        .from(element)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                const pageWidth = pdf.internal.pageSize.getWidth();
                const pageHeight = pdf.internal.pageSize.getHeight();
                pdf.setFontSize(10);
                pdf.text(`Page ${i} of ${totalPages}`, pageWidth - 1, pageHeight - 0.3, { align: 'right' });
            }
        })
        .save()
        .finally(() => {
            this._loaderService.stringSubject.next(false);
            this.applyNegativeCss = false; 
        });
}



getPageBreakYes() {
  if (this.invoicePdfData.invoiceOrderInfoList.length > 1) {
      return true; 
  }

  for (const order of this.invoicePdfData.invoiceOrderInfoList) {
      if (order.invoiceOrderInfoDetailsList.length > 1) {
          return true; 
      }
  }

  return false; 
}

getMarginClass() {
return this.getPageBreakYes() ? 'margin-negative' : 'margin-zero';
}

  dialogOpen(modelToOpn,action){
    this._loaderService.stringSubject.next(true);
    if (modelToOpn==="emailDoc") {
      if(localStorage.getItem("_admincom_documentId_"+this.quoteIdForEmail) == null  && action!= "bol"){
        this.toastr.error('First create commercial invoice.', 'Please', {
          timeOut: 30000,
        });
        setTimeout(() => {
          this._loaderService.stringSubject.next(false);
         }, 1000);
        return;
      }
      setTimeout(() => {
      this._loaderService.stringSubject.next(false);
      const modalRef = this.modalService.open(EmailDocumentComponent, {size: 'lg',centered: false,backdrop: true, windowClass: 'my-custom-modal3'});
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.componentInstance.bolDocId =  action=="commercial" ? localStorage.getItem("_admincom_documentId_"+this.quoteIdForEmail):  this.documentIdForEmail ;
      modalRef.componentInstance.action = action;
      modalRef.result.then((result) => {
        if(result.success){
          this.toastr.success('Email sent successfully.', 'Success', {
            timeOut: 10000,
          });
        }else{
          this.toastr.error('Error, While sending email.', 'Oops', {
            timeOut: 10000,
          });
        }
      });
    }, 1000);
    }
  }

  submit() {
    this.savePdf();
  }

  async savePdf() {
    this.isSubmitRequestProcessing=true;
    this._loaderService.stringSubject.next(true);
    let currentcomp = this;
    var element = document.querySelector('#contentInvoice');
    const name = `INV_${currentcomp.invoicePdfData["invoiceNo"] ? currentcomp.invoicePdfData["invoiceNo"] : ""}.pdf`;
    var opt = {
      margin: 0.1,
      filename: name,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };
    // New Promise-based usage:
    let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then((data: Blob) => {
      return data
    })

    currentcomp.sendPDFToServer(worker);
  }

  sendPDFToServer(pdfContent) {

    const formdata: FormData = new FormData();
    formdata.append("isCustomerEmailReq", this.email);
    formdata.append("customerId", this.customerId);

    var date = new Date().valueOf();
    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });
    var dummyFile = new File([pdfContent], "INV_" + (this.invoicePdfData["invoiceNo"] ? this.invoicePdfData["invoiceNo"] : "" ) + ".pdf");
    formdata.append("file", dummyFile);
    // this._loaderService.stringSubject.next(false);
    
    let url = ServerURLS.uploadInvoice.action;
    let url2 = ServerURLS.getConsolidateInvoice.action + '?orderIds=' + this.listOrderId + '&invoiceDate='+ this.date.toISOString();

    let promise1 = this.scHttpService.postFileHttpService(url, formdata);
    let promise2 = this.scHttpService.postHttpService(url2, '');
    
    forkJoin([promise1, promise2]).subscribe(([res1, res2]) => {
      this.isSubmitRequestProcessing=false;
      if(this.type == "single"){
        sessionStorage.setItem('_adminsingleInvoiceSubmitted', 'true');
      }
      else{
        sessionStorage.setItem('_adminconsolidateInvoiceSubmitted', 'true');
      }

      if (res1['qboStatus']) {
        this.toastr.error(res1['qboStatus']);
      }
      
      if (res2['qboStatus']) {
        this.toastr.error(res2['qboStatus']);
      }
      

      this.listDataService.removeAllDataOfInvoiceList();
      this._loaderService.stringSubject.next(false);
      this.listDataService.isInvoiceSubmited.next(true);
      sessionStorage.setItem(
        "_adminorderDetailInvoiceSubmitted",
        'true'
      );
      this.cancel();
    }, (err: any) => {
      this.isSubmitRequestProcessing=false;
      if(this.type == "single"){
        sessionStorage.setItem('_adminsingleInvoiceSubmitted', 'false');
      }
      else{
        sessionStorage.setItem('_adminconsolidateInvoiceSubmitted', 'false');
      }
      this._loaderService.stringSubject.next(false);
      this.listDataService.isInvoiceSubmited.next(true);
      sessionStorage.setItem(
        "_adminorderDetailInvoiceSubmitted",
        'true'
      );
      this.toastr.error("Something went wrong!")
      this.cancel()
    })

    // promise1.then((res: any) => {
    //   sessionStorage.setItem('singleInvoiceSubmitted', 'true')
    //   this.toastr.success("Document has been submitted", "Success", { timeOut: 5000 });
    //   this.cancel();

    // }).catch((error) => {
    //   this.cancel();
    //   this.toastr.error('Something Went wrong while saving PDF.', 'Please try again.', {
    //     timeOut: 10000,
    //   });
    // });
  }

  cancel() {
    this.modalService.dismissAll();
  }

}
