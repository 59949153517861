import { Component, OnInit, Output } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { AddItemComponent } from '../add-item/add-item.component';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-no-item-view',
  templateUrl: './no-item-view.component.html',
  styleUrls: ['./no-item-view.component.scss']
})
export class NoItemViewComponent implements OnInit {

  isResultPage: boolean;
  quoteItems: any;
  pageNo: number;
  pageSize: number;
  quoteId: string;
  itemFormArray: any;
  pageType: string;
  shipmentModeName: Map<any, any>;
  quote:any;
  shipmentName :string;
  limitAddItem :boolean;

  constructor( private modalService: NgbModal, private scHttpService: ScHttpService, private router: Router, private storageService: ScStorageService, private route: ActivatedRoute) {
     this.quoteItems = [];
     this.shipmentModeName = new Map();

   }

  ngOnInit() {

    //While redirecting from shipment page, modal will popup
    this.isResultPage = false;
    this.quoteId = this.route.snapshot.paramMap.get('quote-id')
    let storage = this.storageService.getBucket("items");
    this.pageType = "addItem";

    if( this.quoteId && !storage){
      this.loadQuoteItems();
    } else if( !storage || storage.length == 0 ) {
      this.open(AddItemComponent);
    } else {
      this.quoteItems = storage;
    }
    this.quote = this.storageService.getBucket("quote");

    if(this.quote){

      this.shpmtModeList();
      
    }
    
    if( this.shipmentName == 'Courier' && this.quoteItems.length == 1){
        this.limitAddItem = true;
    }

  }

  updateForm( e ) {
    this.quoteItems = e;
  }

  loadQuoteItems(): void {

    this.pageNo = 0;
    this.pageSize = 20;

    let url = ServerURLS.getQuoteItems.action + "/" + this.pageNo + "/" + this.pageSize;
    url += "?quoteId=" + this.quoteId;

    let promise1 = this.scHttpService.getHttpService( url, '');
    promise1.then( value => {
      this.quoteItems = value["data"]["content"];
      //console.log(this.quoteItems)
    });
    
  }

  closeResult: any;
  open(content) {
      this.modalService.open(content, {size: 'xl', ariaLabelledBy: 'add-item-component'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      
      this.itemFormArray = this.storageService.getBucket("items");
      //  ;
      let storage = this.storageService.getBucket("items");
      this.quoteItems = storage;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  cancel(){
    this.router.navigateByUrl("shipcarte/dashboard");
  }

  shpmtModeList (): void {
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getShpmtModeList.action,'');
    promise1.then( value => {
      let shpmtModes = value["data"];
      shpmtModes.forEach(element => {
        this.shipmentModeName.set(element.shpngModeId, element.shpngMode);
      });

      this.shipmentName = this.shipmentModeName.get(this.quote.shipmentMode);
  
    });
  
   }
}
