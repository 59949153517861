import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as html2pdf from 'html2pdf.js';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { CommercialInvoiceComponent } from '../../commons/commercial-invoice/commercial-invoice.component';
import { DocumentService } from 'src/app/services/document.service';
import { CreateCommercialPdfViewComponent } from '../create-commercial-pdf-view/create-commercial-pdf-view.component';
import { LoadingService } from 'src/app/services/loader.service';


@Component({
  selector: 'app-commercial-invoice-view',
  templateUrl: './commercial-invoice-view.component.html',
  styleUrls: ['./commercial-invoice-view.component.scss']
})
export class CommercialInvoiceViewComponent implements OnInit {
  sidebarToggle: boolean;
  displayCourier: boolean = false;
  allViewData: any;
  dueDate: any;
  isChecked: boolean;
  frightCharge: any;
  frightChargeCheckBox: boolean;
  subTotalData: any;
  unit: any;
  weightUnit: any;
  shipmantValue: any;
  addSalesTax: number;
  addFreightCosts: number;
  totalAmountDue: any;
  tatalQty: any;
  totalWeight: any;
  orderId: any;
  commercialInvice: boolean = false;
  commercialPdfData: any;
  shipmentItems: any;
  consigneeData: any;
  shipperData: any;
  invoiceId:any;
  subDate: any;
  signatureImageBase64:any='';
  purchaser: any;
  totalUnit:number = 0;
  totalWeightAddition:number = 0;
  totalWeightUnit: string;
  totalOfShpmtItemValue:number = 0;
  itemCurrency: string;
  totalTaxPercentage: any;
  totalDue: any;

  constructor(
    private toastrService: ToastrService,
    private DocumentService: DocumentService,
    private router: Router,
    private modelService: NgbModal,
    private httpService: ScHttpService,
    private  _loaderService: LoadingService
  ) { }

  ngOnInit(): void {
    this.commercialInvice = false;
    this.orderId = sessionStorage.getItem("_admincommercialInvoiceorderId");
    if (!this.orderId) {
      // this.router.navigateByUrl('/CommercialInvoice/list/invoice-list')
    }
    this._loaderService.stringSubject.next(true);
    // this.invoiceId='1b7ad004-948e-4f5c-ad5d-b572fd9a1910';
    this.getViewData(this.orderId);
  }

  signDate:any=''
  getViewData(invoiceId) {
    this.displayCourier = true;
    let url = ServerURLS.ExtCommercialInvoiceView.action+'?extCommercialInvoiceId='+invoiceId+'&actionType=ext';
    let promise = this.httpService.getHttpService(url, '');
    promise.then((result: any) => {
      this.allViewData = result || '';
      this.signDate=result.signDate.split(" ")[0]
      this.signatureImageBase64=result.signImageUrl
      this.commercialPdfData = this.allViewData ? this.allViewData : '--';
      this.shipmentItems = this.allViewData.shipmentItems ? this.allViewData.shipmentItems : '--'
      this.consigneeData = this.allViewData.consignee ? this.allViewData.consignee : '--';
      this.shipperData = this.allViewData.shipper ? this.allViewData.shipper : '--';
      this.purchaser = this.allViewData.purchaser ? this.allViewData.purchaser : '--';

      console.log(this.purchaser,'hvghh')

      
      this.DocumentService.commercialPdfData = this.commercialPdfData;
      this.DocumentService.shipmentItems = this.shipmentItems;
      this.DocumentService.consigneeData = this.consigneeData;
      this.DocumentService.shipperData = this.shipperData;
      this.DocumentService.purchaser = this.purchaser;

      for(let perItem of this.shipmentItems){
        this.totalUnit += perItem.qty;
        this.totalWeightAddition += perItem.totalWeight;
        this.totalOfShpmtItemValue += (perItem.qty * perItem.shipmentItemValue);
        // this.totalOfShpmtItemValue += (perItem.shipmentItemValue);
      }
      this.totalWeightUnit = this.shipmentItems[0].wghtUnit;
      this.itemCurrency = this.shipmentItems[0].shipmentItemValueCurrency;

      let salesTaxAmout = this.commercialPdfData.salesTaxAmout;
      if(this.commercialPdfData.salesTaxIsPercent){
        salesTaxAmout = (this.totalOfShpmtItemValue * salesTaxAmout)/100
      }

      this.totalDue = this.totalOfShpmtItemValue + salesTaxAmout + this.commercialPdfData.freightCost;

      let shipmentData = this.allViewData.shipmentItems ? this.allViewData.shipmentItems : [];



      if (this.allViewData.dueDate) {
        const dueDate = new Date(this.allViewData.dueDate);
        const formattedDueDate = dueDate.toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });
        this.dueDate = formattedDueDate;
      } else {
        this.dueDate = '';
      }

     
      let date = this.allViewData.signDate ? this.allViewData.signDate.split(' ')[0] : '';
      const dateObject = new Date(date);
      const formattedDate = dateObject.toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });
      this.subDate = formattedDate;
     
      if (this.allViewData.shipmentItems && this.allViewData.shipmentItems.length > 0) {
        const firstShipmentItem = this.allViewData.shipmentItems[0];
        this.unit = firstShipmentItem.unit;
        this.weightUnit = firstShipmentItem.wghtUnit;

        this.shipmantValue = this.allViewData.shipmentItems.reduce((acc, res) => acc + (res.shipmentItemValue*res.qty), 0);
        this.tatalQty = this.allViewData.shipmentItems.reduce((acc, res) => acc + res.qty, 0);
        this.totalWeight = this.allViewData.shipmentItems.reduce((acc, res) => acc + (res.totalWeight*res.qty), 0);
      }

      if (this.allViewData.freightChargeCollectionModeList) {
        const data = this.allViewData.freightChargeCollectionModeList.find(data => data.code === this.allViewData.freightChargeCollectionMode);
        if (data) {
          this.frightCharge = data.name;
          this.frightChargeCheckBox = true;
        }
      }

      if (this.allViewData.salesTaxIsPercent) {
        this.addSalesTax = ((this.tatalQty*this.shipmantValue) * this.allViewData.salesTaxAmout) / 100;
      } else {
        this.addSalesTax =  this.allViewData.salesTaxAmout;

      }
      // console.log(this.addSalesTax);

      if (this.allViewData.freightCostIsPercent) {
        this.addFreightCosts = ((this.tatalQty*this.shipmantValue) * this.allViewData.freightCost) / 100;
      } else {
        this.addFreightCosts =  this.allViewData.freightCost;
      }

      if (this.addSalesTax !== null && this.addFreightCosts !== null && this.shipmantValue !== null) {
        //  
        this.totalAmountDue = ((this.tatalQty*this.shipmantValue) + this.addSalesTax + this.addFreightCosts).toFixed(2);
      }
      this.DocumentService.shipmantValue = this.shipmantValue;

      this.displayCourier = false;
      this._loaderService.stringSubject.next(false);
    }).catch((err) => {
      this.displayCourier = false;
      this._loaderService.stringSubject.next(false);
      this.toastrService.error(err.message, 'Something Went Wrong');
    })
  }
  backButFun() {
    this.router.navigateByUrl('shipcarte/commercialInvoiceList');
  }

  editModeFun() {
    sessionStorage.setItem("_admincommercialInvoice", this.orderId);
    this.router.navigateByUrl('/commercialInvoice/edit');
  }



    openInvoice() {
      const modalRef = this.modelService.open(CreateCommercialPdfViewComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'viewCommercialInvoicepdf' });
      this.router.events.subscribe((val) => {
        // modalRef.close(); 
        console.log(val);
      });
      modalRef.result.then((result) => {
      });
    }
   
  
  // Function to generate and download PDF
  generatePDF() {
    this.commercialInvice = true;
    const element = document.getElementById('DownloadCommercialPdfData');

    if (!element) {
      console.error('Element with id "invoicePdf" not found.');
      return;
    }
    // Set the PDF generation options
    const options = {
      margin: 10,
      filename: 'Commercial_Invoice.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', compressPDF: true },
      // pagebreak: { mode: 'avoid-all' },
    };
    // Generate the PDF
    html2pdf().set(options).from(element).save()
  }

  backFun() {
    this.router.navigateByUrl('/CommercialInvoice/list/invoice-list');
  }

  // closeModel() {
  //   this.modelService.dismissAll();
  // }


}
