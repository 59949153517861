import { Component } from '@angular/core';
import { LoadingService } from '../services/loader.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  constructor(
    private _loader: LoadingService
  ) { }
  showSpinar=false;
  singleTimer:any;
  withOFtd=40;
  ngOnInit() {
    this._loader.stringSubject.subscribe((isLoading: boolean) =>{
      if (isLoading) {
        this.showSpinar=true;
        this.withOFtd=40;
        this.singleTimer = setInterval(() => {
          if (this.withOFtd>=100) {
            this.withOFtd=0;
          }
          this.withOFtd=this.withOFtd+4;
        }, 100)
      } else {
        this.showSpinar=false;
        clearInterval(this.singleTimer);
        this.withOFtd=10;
      }
    });
    setTimeout(() => {
      if (this.showSpinar) {
        this.showSpinar=false;
      }
    }, 40000);
  }

}
