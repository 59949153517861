import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Server } from 'http';
import { url } from 'inspector';
import { ToastrService } from 'ngx-toastr';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UnitedStatesDateParserFormatter } from 'src/app/services/common/sc-common.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { LoadingService } from 'src/app/services/loader.service';
import { EnvironmentConstants, ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-carrier-invoice-receipt',
  templateUrl: './carrier-invoice-receipt.component.html',
  styleUrls: ['./carrier-invoice-receipt.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: UnitedStatesDateParserFormatter }]
})
export class CarrierInvoiceReceiptComponent implements OnInit{
  @ViewChild('loadContent', undefined) loadContent;
  invoiceDate: NgbDateStruct;
  invoiceNumber: any = '';
  invoiceDueDate: NgbDateStruct;
  dataFound: boolean = false;
 
  page = 0;
  isDesc: boolean;
  pageSize = 10;
  dataList: any = [];
  contractSelectedCount: number = 0;
  totalFuelCharges: number = 0.00;
  totalSelected: number = 0.00;
  orderDetailsObject: any = [];
  balanceDue: number = 0.00;
  selectedRowArray: any = [];
  currencyCode: any = '';
  carrierId: any = '';
  carrierContractAmount:any;
  carrierDetails: any;
  disabledRowArray: any = [];
  customBrokersInfo: any = [];
  sortBy: any;
  termsDaysData: any = '';
  countryData = {
    success: [
      {
        countryPhCode: "+1",
        countryName: "Canada",
        countryCode: "CA",
        provinceState: [
          {
            code: "AB",
            name: "Alberta"
          },
          {
            code: "BC",
            name: "British Columbia"
          },
          {
            code: "MB",
            name: "Manitoba"
          },
          {
            code: "NB",
            name: "New Brunswick"
          },
          {
            code: "NL",
            name: "Newfoundland and Labrador"
          },
          {
            code: "NT",
            name: "Northwest Territories"
          },
          {
            code: "NS",
            name: "Nova Scotia"
          },
          {
            code: "NU",
            name: "Nunavut"
          },
          {
            code: "ON",
            name: "Ontario"
          },
          {
            code: "PE",
            name: "Prince Edward Island"
          },
          {
            code: "QC",
            name: "Quebec"
          },
          {
            code: "SK",
            name: "Saskatchewan"
          },
          {
            code: "YT",
            name: "Yukon"
          }
        ]
      },
      {
        countryPhCode: "+1",
        countryName: "United States",
        countryCode: "US",
        provinceState: [
          {
            code: "AL",
            name: "Alabama"
          },
          {
            code: "AK",
            name: "Alaska"
          },
          {
            code: "AS",
            name: "American Samoa"
          },
          {
            code: "AZ",
            name: "Arizona"
          },
          {
            code: "AR",
            name: "Arkansas"
          },
          {
            code: "CA",
            name: "California"
          },
          {
            code: "CO",
            name: "Colorado"
          },
          {
            code: "CT",
            name: "Connecticut"
          },
          {
            code: "DE",
            name: "Delaware"
          },
          {
            code: "DC",
            name: "District of Columbia"
          },
          {
            code: "FL",
            name: "Florida"
          },
          {
            code: "GA",
            name: "Georgia"
          },
          {
            code: "GU",
            name: "Guam"
          },
          {
            code: "HI",
            name: "Hawaii"
          },
          {
            code: "ID",
            name: "Idaho"
          },
          {
            code: "IL",
            name: "Illinois"
          },
          {
            code: "IN",
            name: "Indiana"
          },
          {
            code: "IA",
            name: "Iowa"
          },
          {
            code: "KS",
            name: "Kansas"
          },
          {
            code: "KY",
            name: "Kentucky"
          },
          {
            code: "LA",
            name: "Louisiana"
          },
          {
            code: "ME",
            name: "Maine"
          },
          {
            code: "MD",
            name: "Maryland"
          },
          {
            code: "MA",
            name: "Massachusetts"
          },
          {
            code: "MI",
            name: "Michigan"
          },
          {
            code: "MN",
            name: "Minnesota"
          },
          {
            code: "MS",
            name: "Mississippi"
          },
          {
            code: "MO",
            name: "Missouri"
          },
          {
            code: "MT",
            name: "Montana"
          },
          {
            code: "NE",
            name: "Nebraska"
          },
          {
            code: "NV",
            name: "Nevada"
          },
          {
            code: "NH",
            name: "New Hampshire"
          },
          {
            code: "NJ",
            name: "New Jersey"
          },
          {
            code: "NM",
            name: "New Mexico"
          },
          {
            code: "NY",
            name: "New York"
          },
          {
            code: "NC",
            name: "North Carolina"
          },
          {
            code: "ND",
            name: "North Dakota"
          },
          {
            code: "OH",
            name: "Ohio"
          },
          {
            code: "OK",
            name: "Oklahoma"
          },
          {
            code: "OR",
            name: "Oregon"
          },
          {
            code: "PA",
            name: "Pennsylvania"
          },
          {
            code: "PR",
            name: "Puerto Rico"
          },
          {
            code: "RI",
            name: "Rhode Island"
          },
          {
            code: "SC",
            name: "South Carolina"
          },
          {
            code: "SD",
            name: "South Dakota"
          },
          {
            code: "TN",
            name: "Tennessee"
          },
          {
            code: "TX",
            name: "Texas"
          },
          {
            code: "VI",
            name: "U.S. Virgin Islands"
          },
          {
            code: "UT",
            name: "Utah"
          },
          {
            code: "VT",
            name: "Vermont"
          },
          {
            code: "VA",
            name: "Virginia"
          },
          {
            code: "WA",
            name: "Washington"
          },
          {
            code: "WV",
            name: "West Virginia"
          },
          {
            code: "WI",
            name: "Wisconsin"
          },
          {
            code: "WY",
            name: "Wyoming"
          }
        ]
      },
    ]
  };
  selectedContractsEnabled: boolean = false;
  selectedOrdersCarrierId: any;
  selectedOrdersCurrency: any;
  selectedOrdersCarrierLogo: any;
  selectedOrdersCarrierName: any;
  selectedOrdersQuoteIdsStr: any;
  constructor(
    private route: Router,
    private httpService: ScHttpService,
    private modelService: NgbModal,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private  _loaderService: LoadingService
  ) {
    this.sortBy = {
      'parentContractId': 'downOrder',
      'orderCrtdDate': 'downOrder',
      "fuelCharge": 'downOrder',
      'totalCost': 'downOrder',
      'currencyUnit': 'downOrder',
      'amountDue': 'downOrder'
    }

    this.activatedRoute.queryParams.subscribe(params => {
      // Access query parameters here
      // console.log(params); // This will log all query parameters
      console.log(params['selectedOrdersCarrierId']); // Access a specific query parameter by name
      console.log(params['selectedOrdersCurrency']); // Access a specific query parameter by name

      if(params['selectedOrdersCarrierId'] && params['selectedOrdersCurrency']){
        this.selectedContractsEnabled = true;
        this.pageSize = 50;
        this.selectedOrdersCarrierId = params['selectedOrdersCarrierId'];
        this.selectedOrdersCurrency = params['selectedOrdersCurrency'];
        this.selectedOrdersCarrierLogo = params['selectedOrdersCarrierLogo'];
        this.selectedOrdersCarrierName = params['selectedOrdersCarrierName'];
        this.selectedOrdersQuoteIdsStr = params['quoteIdList'];
      }
    });
  }

  ngOnInit(): void {
    console.log(this.selectedOrdersCarrierId, this.selectedOrdersCarrierName, this.selectedOrdersCarrierLogo, this.selectedOrdersCurrency)
    if(this.selectedContractsEnabled){
      this.getCarrierDetails(this.selectedOrdersCarrierId);
    }
    this._loaderService.stringSubject.next(true);
  }
  goBacktoContractProcessing() {
    this.route.navigateByUrl('/shipcarte/allinvoices/contractprocessing')
  }
  getIdOfCarrier(value: any) {
    let url1 = ServerURLS.fetchIdOfCarrierInInvoice.action + "?searchKey=" + value;
    let promise = this.httpService.getHttpService(url1, '');
    promise.then((res) => {
      this.customBrokersInfo = res;
    })
      .catch((err) => {

      })
  }
  isDataLoading:any=''
  getAllContractList( currencyCode) {
    // this.selectedRowArray = [];
    this.dataFound = false;
    this.isDataLoading='Loading Your Option, Please Wait...'
    this.openModel();
    if (currencyCode !== '') {
      const res=JSON.parse(localStorage.getItem('selectedItemsForInvoice'))
      if(res) {
        this.isDataLoading=''
        this.dataList = res;
        if(res.length> 0){
            let totalCharge = 0;
            let selectedRows = [];
            let fuelCharge = 0;
            let balanceDue = 0;
            res.forEach((data,index)=>{
              totalCharge = totalCharge + (data.totalCost ? parseFloat(data.totalCost) : 0)
              fuelCharge = fuelCharge + (data.fuelSurcharge ? parseFloat(data.fuelSurcharge) : 0)
              balanceDue = balanceDue + (data.amountDue ? parseFloat(data.amountDue) : 0)
              selectedRows.push(index);
            })

            this.totalSelected = totalCharge;
            // this.carrierContractAmount=totalCharge
            // console.log(totalCharge,"totalCharge")
            this.totalFuelCharges = fuelCharge;
            this.balanceDue = balanceDue;
            this.selectedRowArray = selectedRows;
            this.contractSelectedCount = this.selectedRowArray.length;
            if (this.selectedRowArray.length === 0) {
              this.dataFound = false;
            } else {
              this.dataFound = true;
            }
          // }
          this.checkAmount();

          this.carrierContractAmount = ""
          this.checkAmount();
        }
        
        this.closeModel();
      }
    } else {
      this.closeModel();
      this.toastr.error("Please Select the currency or Carrier First");
    }


  }
  openModel() {
    this.modelService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' });
    setTimeout(() => {
      this._loaderService.stringSubject.next(false);
    }, 2000);
  }
  closeModel() {
    this.modelService.dismissAll();
  }
 
  setCheckBoxTrueOrFalse(id: any, fuel: any, total: any) {
    // if (this.carrierContractAmount === null || this.carrierContractAmount === undefined || this.carrierContractAmount === 0) {
    //   this.toastr.error("Please Enter the Amount")
    //   this.selectedRowArray = [];

    //   this.dataFound = false;
    // } else {
      let fuelCharge = 0;
      let totalCharge = 0;
      let balanceDue = 0;
      if (this.selectedRowArray.includes(id)) {
        this.selectedRowArray.forEach((element, index) => {
          if (element === id) {
            this.selectedRowArray.splice(index, 1)
          }
        });
      } else {
        this.selectedRowArray.push(id)
      }
      this.selectedRowArray.forEach((element, index) => {
        fuelCharge = fuelCharge + parseFloat(this.dataList[this.selectedRowArray[index]].fuelSurcharge)
        totalCharge = totalCharge + parseFloat(this.dataList[this.selectedRowArray[index]].totalCost);
        balanceDue = balanceDue + parseFloat(this.dataList[this.selectedRowArray[index]].amountDue);
      });
      
      this.totalSelected = totalCharge
      this.totalFuelCharges = fuelCharge
      this.balanceDue = balanceDue

      this.contractSelectedCount = this.selectedRowArray.length;
      if (this.selectedRowArray.length === 0) {
        this.dataFound = false;
      } else {
        this.dataFound = true;
      }
    // }
    this.carrierContractAmount=parseFloat(this.totalSelected.toFixed(2));
    this.setAmountValueOnblur()
    this.checkAmount();


  }
  checkAmount() {
    if (this.selectedRowArray.length > 0) {
      if (this.carrierContractAmount == parseFloat(this.totalSelected.toFixed(2))) {

        this.dataFound = true
      } else {

        this.dataFound = true
      }
    }

    

  }
  selectAllTheRows(value: any) {

    let fuelCharge = 0;
    let totalCharge = 0.00;
    let dueBalance = 0.00;
    if (value) {
      this.selectedRowArray = [];
      this.dataList.forEach((element, index) => {
        this.selectedRowArray.push(index)
      });
      this.selectedRowArray.forEach((element, index) => {
        fuelCharge = fuelCharge + parseFloat(this.dataList[this.selectedRowArray[index]].fuelSurcharge)
        totalCharge = totalCharge + parseFloat(this.dataList[this.selectedRowArray[index]].totalCost);
        dueBalance = dueBalance + parseFloat(this.dataList[this.selectedRowArray[index]].amountDue);
        this.dataFound = true;
      });
      this.totalSelected = totalCharge
      this.totalFuelCharges = fuelCharge
      this.balanceDue = dueBalance
      this.contractSelectedCount = this.selectedRowArray.length;
    } else {
      this.selectedRowArray = [];
      this.totalFuelCharges = 0;
      this.totalSelected = 0;
      this.balanceDue = 0;
      this.dataFound = false;
    }
    this.contractSelectedCount = this.selectedRowArray.length;
    this.checkAmount();
  }
  getTermsValueAccordingToFlag(value: any) {
    let url1 = ServerURLS.getCarrierContractDetailsInInvoice.action + "?carrierId=" + this.carrierId + "&currency=" + value;
    let promise1 = this.httpService.getHttpService(url1, '');
    promise1.then((res: any) => {
      this.termsDaysData = res.terms.split(' ')[0];
      this.setInvoiceDueDate(this.invoiceDate)


    })
      .catch((err) => {

      })
  }
  getSelectedflagValue(value: any) {

    this.totalSelected = 0;
    this.contractSelectedCount = 0;
    this.totalFuelCharges = 0;
    if (this.carrierId) {
      if (value.countryCode == 'US') {
        this.currencyCode = "USD";
        this.getTermsValueAccordingToFlag(this.currencyCode)
        this.getAllContractList( this.currencyCode);
      } else if (value.countryCode == 'CA') {
        this.currencyCode = 'CAD'
        this.getTermsValueAccordingToFlag(this.currencyCode)
        this.getAllContractList(  this.currencyCode);
      }

      if (this.termsDaysData) {
        this.setInvoiceDueDate(this.invoiceDate)
      }
      if (this.invoiceDate) {
        this.setInvoiceDueDate(this.invoiceDate)
      }
    } else {
      this.toastr.error("Plese Select the carrier First")
    }

  }
  setAmountValue(value: any) {
    this.carrierContractAmount = parseFloat(value)
    this.checkAmount();
  }

  setAmountValueOnblur() {
    this.carrierContractAmount = parseFloat(this.carrierContractAmount).toFixed(2);
    this.checkAmount();
  }
  getCarrierName(value: any) {
    this.getIdOfCarrier(value);
  }
  getCarrierDetails(value: any) {
    this.carrierId = value;


    let url1 = ServerURLS.getCarrierContractDetailsInInvoice.action + "?carrierId=" + value;
    let promise = this.httpService.getHttpService(url1, '');
    promise.then((res: any) => {
      this.carrierDetails = res;
      // if(this.selectFlagValue!==''){
      // if(this.selectFlagValue=='CA'){
      //   this.currencyCode="CAD"
      // }
      // if(this.selectFlagValue=="US"){
      //   this.currencyCode="USD"
      // }
      if (this.currencyCode) {
        this.getAllContractList(  this.currencyCode)
        this.getTermsValueAccordingToFlag(this.currencyCode)
      }

      if(this.selectedContractsEnabled){
        let selectedCountryCode = this.selectedOrdersCurrency == 'CAD' ? 'CA' : 'US';
        const countryObj = this.countryData.success.find(country => country.countryCode === selectedCountryCode);
        this.getSelectedflagValue(countryObj);
      }

      // }
    })
      .catch((err) => {
        this.toastr.error("This is invalid carrier")
      })
  }
  editContract(data){
  //   {
  //     "carrierprocessingEdit": "O-00-04229",
  //     "quoteId": "161f62b1-e154-497a-b3d1-dcabf292c836",
  //     "carrierId": "1122064d-0da0-40f1-81ff-a60aed783d0f",
  //     "probill": null,
  //     "contractId": "C-00-04229",
  //     "carrierName": "ABF",
  //     "customerId": "14a79077-0aae-49cf-94c1-71da236a6b52",
  //     "currencyUnit": "USD"
  // }
  let quoteIdForDoc='';
  if (data.quoteIds) {
    quoteIdForDoc=data.quoteIds.split(',')[0]
  }
    let obj={ 
      "carrierprocessingEdit": data.customOrderId, 
      "quoteId": quoteIdForDoc?quoteIdForDoc:data.quoteId, 
      "carrierId": data.carrierId, 
      "probill": data.proNumber, 
      "contractId": data.parentContractId, 
      'carrierName': data.carrierName, 
      "customerId": data.customerId, 
      "currencyUnit": data.currencyCode 
    }
    sessionStorage.setItem('_admincarrier',JSON.stringify(obj))
    this.route.navigateByUrl('shipcarte/allinvoices/carrierprocessingEdit?isReceiptEdit=true')
  }
  setInvoiceDueDate(value: any = null) {
    // If value is not provided, use today's date
    const today = new Date();
    if (!value) {
        value = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
    }

    // Set the invoice date
    this.invoiceDate = { year: value.year, month: value.month, day: value.day };

    // Create a date object from the value
    let date = new Date(`${value.year}-${value.month}-${value.day}`);

    // Function to add days to a date
    function addDays(date, days) {
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + parseInt(days));
        return copy;
    }

    // Calculate the due date by adding termsDaysData to the invoice date
    const monthRollsOver = addDays(date, this.termsDaysData);

    // Format the due date
    let invoiceDueDate = `${monthRollsOver.getFullYear()}-${monthRollsOver.getMonth() + 1}-${monthRollsOver.getDate()}`;
    this.invoiceDueDate = {
        year: monthRollsOver.getFullYear(),
        month: monthRollsOver.getMonth() + 1,
        day: monthRollsOver.getDate()
    };
}

  sort(property: any) {

    this.isDesc = !this.isDesc; //change the direction
    let direction = this.isDesc ? 1 : -1;
    this.sortBy[property] = !this.isDesc ? "downOrder" : "upOrder";

    if (property == "amountDue" || property == "orderCrtdDate" || property == 'totalCost' || property == 'fuelSurcharge') {
      this.dataList.sort(function (a, b) {
        if (parseFloat(a[property]) < parseFloat(b[property])) {
          return -1 * direction;
        }
        else if (parseFloat(a[property]) > parseFloat(b[property])) {
          return 1 * direction;
        }

        else {
          return 0;
        }
      });
    }

    else {
      this.dataList.sort(function (a, b) {
        if (a[property].toLowerCase() < b[property].toLowerCase()) {
          return -1 * direction;
        }
        else if (a[property].toLowerCase() > b[property].toLowerCase()) {
          return -1 * direction
        }
        else {
          return 0;
        }
      })
    }
  }
  setInvoiceNumber(invoiceNumber: any) {

    this.invoiceNumber = invoiceNumber;
  }
  saveCarrierInvoiceRecieptData() {
    console.log(this.dataList)
  
    let sumDue = [];
    this.dataList.forEach((element, index) => {
      if (this.selectedRowArray.includes(index)) {
        let obj = {
          "due": this.dataList[index].amountDue
        };
        sumDue.push(obj);
      }
    });
    
    const totalSum = sumDue.reduce((total, item) => {
     
      return total + parseFloat(item.due);

    }, 0);
    
    console.log('Total Sum of due:', totalSum);
    
   
    console.log(this.carrierContractAmount, "sumDue", sumDue);
    if(this.carrierContractAmount==totalSum){
      this.openModel();
      if (this.carrierId) {
        if (this.invoiceNumber !== '') {
          if (this.currencyCode) {
            if (this.invoiceDate) {
              if (this.carrierContractAmount) {
                if (this.dataFound) {
                  let dataObj = [];
                  let orderIds='';
                  this.dataList.forEach((element, index) => {
                    if (this.selectedRowArray.includes(index)) {
                      let obj = {
                        "orderId": this.dataList[index].orderId,
                        "totalCharge": this.dataList[index].totalCost,
                        "fuelCharge": this.dataList[index].fuelCharge,
                        "currency": this.dataList[index].currencyUnit,
                        "due": this.dataList[index].amountDue,
                        "carrierId":this.dataList[index].carrierId,
                        "quoteIds":this.dataList[index].quoteIds
                      }
                      orderIds=orderIds?(orderIds+",")+this.dataList[index].orderIds:this.dataList[index].orderIds
                      dataObj.push(obj)
                    }
                  });
                  let requestObj = {
                    "invoiceDetails": {
                      "invoiceNo": this.invoiceNumber,
                      "invoiceDate": this.invoiceDate.month + '-' + this.invoiceDate.day + '-' + this.invoiceDate.year,
                      "invoiceDueDate": this.invoiceDueDate.month + '-' + this.invoiceDate.day + '-' + this.invoiceDate.year,
                      "terms": this.termsDaysData,
                      "amount": this.carrierContractAmount,
                      "currency": this.currencyCode
                    },
                    "orderIds":orderIds,
                    "orderDetails": dataObj
                  }
                  let url = ServerURLS.carrierInvoiceReceiptSave.action;
                  let promise = this.httpService.postHttpService(url, requestObj);
                  promise.then((res: any) => {
                    this.closeModel();
                    this.toastr.success("Success! Carrier invoice Receipt Generated")
                    if(res.qboStatus){
                      this.toastr.error(res.qboStatus)

                    }
                    this.route.navigateByUrl('shipcarte/allinvoices/contractprocessing');
                    localStorage.removeItem('selectedItemsForInvoice');
                  })
                    .catch((err: any) => {
                      this.closeModel();
                      this.toastr.error("Something went wrong!")
                    })
                  // this.route.navigateByUrl('shipcarte/invoice-list')
                  // this.toastr.success("Contract Invoice Generated Successfully")
                } else {
                  this.closeModel();
                  this.toastr.error("Please Select the contract")
                }
              } else {
                this.closeModel();
                this.toastr.error("Please enter the amount")
              }
  
            } else {
              this.closeModel();
              this.toastr.error('Please select the invoice date')
            }
          } else {
            this.closeModel();
            this.toastr.error("Please select the currecy Code")
          }
        } else {
          this.closeModel();
          this.toastr.error("Please enter the invoice number");
        }
      } else {
        this.closeModel();
        this.toastr.error("Please select the carrier")
      }
  
    }

    else{
      this.toastr.error("Please fill correct due balance")

    }

  }
  isLoading: boolean = false
  @HostListener("window:scroll", ["$event"])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false
  ngAfterViewInit() {
    this.scrollChangeCallback = () => this.scrolHandlerInvoiceReceipt(event);
    window.addEventListener('scroll', this.scrollChangeCallback, true);
  }
  allDataLoaded: any = false;
  scrolHandlerInvoiceReceipt(e) {
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !this.isLoading) {
        this.isLoading = true
        if (!this.allDataLoaded) {
          // this.fetchListOfDataToAppend();
        } else {
          this.isLoading = false;
        }
      }
    } else {
      this.showButton = true;
    }
    this.currentPosition = scroll;
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrolHandlerInvoiceReceipt, true);
 
  }
  fetchListOfDataToAppend(){
    this.page=this.page+1
      let url = ServerURLS.getContractProcessingContractList.action + this.page + '/' + this.pageSize + "?carrierId=" + this.carrierId + "&currency=" + this.currencyCode;
      if(this.selectedContractsEnabled && this.selectedOrdersQuoteIdsStr){
        url = url + '&quoteIdList='+this.selectedOrdersQuoteIdsStr;
      }
      let promise = this.httpService.getHttpService(url, '');
      promise.then((res: any) => {
        this.isLoading=false
        this.dataList =this.dataList.concat(res.response);
        if(res.response.length == 0){
          this.isLoading=false;
          this.allDataLoaded=true;
        }
      })
        .catch((err) => {

 
        })
 
  }
}
