import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ServerURLS } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})



export class DocumentService {
  pdfData:any;
  baseUrl:any;
  customerData$ = new BehaviorSubject('');
  commercialPdfData:any;
  shipmentItems:any;
  consigneeData:any;
  invoiceId:any;
  purchaser: any;
  shipperData:any;
  shipmantValue:any;
  constructor(private http:HttpClient) { }

  generateBOLNumber(quoteId){
    return this.http.get(ServerURLS.generateBOLNumber.action+ "?quoteId="+quoteId,{ withCredentials: true });
  }

  getData(quoteId){
    return this.http.get(ServerURLS.getBolData.action+ "?quoteId="+quoteId,{ withCredentials: true });
  }


  getInvoicePdfData(quoteId?: string,orderIds?:string[]) {
    const orderId = localStorage.getItem('invoiceOrderId');    
    if (!orderId && !orderIds) {
      throw new Error('Order ID is not available in localStorage');
    }
  
    return this.http.get(`${ServerURLS.getInvoicePdfData.action}?orderIds=${orderIds ? orderIds : orderId}`, { withCredentials: true });
  }
  

  sendEmail(dataObj){
    //const myheader = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    //const myheader = new HttpHeaders({});

    return this.http.post(ServerURLS.sendBolEmail.action,dataObj ,{ withCredentials: true,
      headers: myheader
      }).pipe(map(response => {
      return response;
    }));
  }

  getCarrierContractPDFData(quoteId:string){
    return this.http.get(ServerURLS.carrierContractPDFInfo.action+ "?quoteId="+quoteId,{ withCredentials: true });
  }


  sendEmailInvoice(dataObj, invoice){
    //const myheader = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    //const myheader = new HttpHeaders({});

    return this.http.post(ServerURLS.sendOrderInvoiceEmail.action+ "?invoiceNumber="+invoice,dataObj ,{ withCredentials: true,
      headers: myheader
      }).pipe(map(response => {
      return response;
    }));
  }

  savePdf(obj){
    //const myheader = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    //const myheader = new HttpHeaders({ "Content-Type": "multipart/form-data" });
    const myheader = new HttpHeaders({});
    //const myheader = new HttpHeaders({ "Content-Type": undefined });
    
    //var endPoint = "http://solaceinfotech.co.in/al.php"
    var endPoint = ServerURLS.saveBolPdf.action;
    

    //return this.http.post(environment.apiPath+ "/api/bol/savePDF",obj ,{ withCredentials: true,
    return this.http.post(endPoint, obj ,{ withCredentials: true,
      headers: myheader
      }).pipe(map(response => {
      return response;
    }));
  }

  saveCommercialPdf(obj){

    const myheader = new HttpHeaders({});
    return this.http.put(ServerURLS.commercialInvoiceSavePDF.action, obj, {
    headers: myheader, withCredentials: true
  }).pipe(map(response => {
      return response;
    }));
  }

  sendCommercialEmail(dataObj){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(ServerURLS.sendCommercialInvoiceEmail.action,dataObj ,{ withCredentials: true,
      headers: myheader
      }).pipe(map(response => {
      return response;
    }));
  }
  sendInsuranceEmail(dataObj){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(ServerURLS.sendInsuranceEmail.action,dataObj ,{ withCredentials: true,
      headers: myheader
      }).pipe(map(response => {
      return response;
    }));
  }

  saveCarrierContract(obj){
    const myheader = new HttpHeaders({});
    return this.http.put(ServerURLS.carrierContractSavePDF.action, obj, {
      headers: myheader, withCredentials: true
    }).pipe(map(response => {
      return response;
    }));
  }

  sendCarrierContractEmail(dataObj){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(ServerURLS.sendCarrierContractEmail.action,dataObj ,{ withCredentials: true,
      headers: myheader
      }).pipe(map(response => {
      return response;
    }));
  }
  
}
