import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
  private currencySymbols: { [key: string]: string } = {
    USD: '$',
    CAD: '$',
    EUR: '€',
    GBP: '£',
    JPY: '¥',
    // Add more currency symbols as needed
  };

  transform(currencyCode: string): string {
    return this.currencySymbols[currencyCode] || '';
  }
}
