import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';

// import { getQuoteService } from 'src/app/services/get-quote/get-quote.service';
// import { QuickBolService } from 'src/app/services/quick-bol-v2/quick-bol.service';

@Component({
  selector: 'app-view-bol-doc',
  templateUrl: './view-bol-doc.component.html',
  styleUrls: ['./view-bol-doc.component.scss']
})
export class ViewBolDocComponent implements OnInit {

  BOLd:any;
  data:any
  resultData:any;
  dynamicDimension:any;
  declaredValue:number;
  totalItem:number;
  packagesArray:any=[];
  totalPackageCountArray:any=[]
  constructor(
    private commonService:ScCommonService,
    public activeModal: NgbActiveModal,
    private scHttpService: ScHttpService
    ) { }

  ngOnInit(): void {
    this.resultData=this.commonService.bolResultData;
    this.getData();
  }
shippingLabel: boolean;
  getData() {
    let fetchQuickBolFormData = ServerURLS.fetchQuickBolFormData.action + "?bolId=" + this.commonService.bolId;
    let promise = this.scHttpService.getHttpService(fetchQuickBolFormData, '');
    promise.then(result => {
      console.log('getDatahere', result)
      this.resultData = result;
      // Packages handling logic
      if (this.resultData.description === 'Courier' && this.resultData.packagingDetails) {
        this.resultData.packagingDetails.forEach(element1 => {
          this.packagesArray.push({
            'packageName': element1.packagingType,
            'packageId': element1.packagingId
          });
        });
      } else if (this.resultData.description !== 'Full Truckload (TL) Shipment' && this.resultData.packagingTypeDetails) {
        this.resultData.packagingTypeDetails.forEach(element1 => {
          this.packagesArray.push({
            'packageName': element1.packagingName,
            'packageId': element1.packagingId
          });
        });
      }


        this.commonService.bolResultData = this.resultData;
  
        this.resultData['items'].sort((a, b) => {
          return a.displayOrder - b.displayOrder
        });
        localStorage.setItem('_admincom_documentId_' + this.commonService.quoteId, result['documentId']);

        if (this.BOLd['shipmentMode'] == "Courier") {
          this.shippingLabel = true;
        }
        else {
          this.shippingLabel = false;

        }

        if (this.BOLd['shipperCountry'] != this.BOLd['consigneeCountry']) {
          // this.showComInv = true;
        } else {
          // this.showComInv = false;
        }
        this.dynamicDimension = {
          length: 0,
          width: 0,
          height: 0,
          weight: 0
        }
        this.totalItem = 0
        for (let i in result['items']) {
          this.totalItem += 1
          this.commonService.totalItemBol = this.totalItem;
          if (result['items'][i] && result['items'][i].unit == 'cm') {
            result['items'][i].length = result['items'][i].length ;
            result['items'][i].totalWeight = result['items'][i].totalWeight ;
            result['items'][i].height = result['items'][i].height ;
            result['items'][i].breadth = result['items'][i].breadth ;
            this.declaredValue += result['items'][i]['shipmentItemValue'] * result['items'][i]['qty'];
            this.commonService.declaredBol = this.declaredValue;
            this.dynamicDimension['length'] += result['items'][i].length;
            this.dynamicDimension['width'] += result['items'][i].breadth;
            this.dynamicDimension['height'] += result['items'][i].height;
            this.dynamicDimension['weight'] += result['items'][i].totalWeight * result['items'][i]['qty'];
            this.commonService.dimensionBOL = this.dynamicDimension;
          } else {
            this.dynamicDimension['length'] += result['items'][i].length;
            this.dynamicDimension['width'] += result['items'][i].breadth;
            this.dynamicDimension['height'] += result['items'][i].height;
            this.dynamicDimension['weight'] += result['items'][i].totalWeight * result['items'][i]['qty'];
            this.declaredValue += result['items'][i]['shipmentItemValue'];
            this.commonService.declaredBol = this.declaredValue;

            this.commonService.dimensionBOL = this.dynamicDimension;

          }
        }
        this.BOLd['shipperAddressfirstName'] = result['shipperAddress']['firstName'];
        this.BOLd['shipperAddresslastName'] = result['shipperAddress']['lastName'];
        this.BOLd['consigneeAddressfirstName'] = result['consigneeAddress']['firstName'];
        this.BOLd['consigneeAddresslastName'] = result['consigneeAddress']['lastName'];
        this.BOLd['brokerName'] = result['brokerName'];
        this.BOLd['Phone'] = result['brokerPhone'];
        this.BOLd['brokerEmail'] = result['brokerEmail'];
        this.BOLd['carrierServiceName'] = result['carrierServiceName'];
        this.BOLd['carrierAccountNo'] = result['carrierAccountNo'];

        this.BOLd['deliveryDate']= result['deliveryDate'];
        //this.BOLd['shipcarteLogoSmall']= result['shipcarteLogoSmall'];
        this.BOLd['carrierLogo'] = result['carrierLogo'];
        setInterval(() => {
          let shipLogo = sessionStorage.getItem('_adminshipSmallLogo');
          let carrierLogo = sessionStorage.getItem('_admincarrierLogo');
          // if(shipLogo && carrierLogo){
          // this.shipcarteSmallLogo = shipLogo;
          // this.carrierLogo = carrierLogo
          // clearInterval(inter)
          // }
        }, 1000)

        convertImageToBase64(this.BOLd['shipcarteLogoSmall'], function (data) {
          sessionStorage.setItem('_adminshipSmallLogo', data);

        })
        convertImageToBase64(this.BOLd['carrierLogo'], function (data) {
          sessionStorage.setItem('_admincarrierLogo', data);

        })
        function convertImageToBase64(imgUrl, callback) {
          const image = new Image();
          image.crossOrigin = 'anonymous';
          image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;
            ctx.drawImage(image, 0, 0);
            const dataUrl = canvas.toDataURL();
            callback && callback(dataUrl)
          }
          image.src = imgUrl;
        }
        this.commonService.bolInvoiceDetails = this.BOLd;
        var self = this;
        setTimeout(function () {
          if (result) {
            //self.savePdf();
            // if (self.isCommercialInvoiceGenerated) {
            //   self.saveCommercialPdf();
            // }
            // else {
            //   self.displayLoader = false;
            // }
          } else {
            // self.displayLoader = false;
          }
        }, 3000);

      }, error => {

          

      });
    }

  closeModal() { this.activeModal.close(); }

}
